import React from 'react';
import styles from './CalculatorsListElement.module.css'
import {CALCULATE_ROUTE, CREATE_DESIGN_ROUTE} from "../../utils/const";
import {useNavigate} from "react-router-dom";

const CalculatorsListElement = ({name, image, route, text}) => {
    const navigate = useNavigate();

    return (
        <div className={styles.yellow} onClick={() => {
            navigate(CALCULATE_ROUTE + route);
        }}>
            <div className={styles.calculateCard}>
                <img src={image} alt={name} className={styles.calculateImage}/>
                <div className={styles.calculateCardHeader}>
                    <div className={styles.calculateCardName}>
                        {name}
                    </div>
                    <div className={styles.calculateCardText}>
                        {text}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CalculatorsListElement;