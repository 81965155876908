import React, {useContext} from 'react';
import './EditConcretePrices.module.css'
import EditPricesBusinessCard from "./EditPricesComponents/EditPricesBusinessCard";
import EditPricesLeaflet from "./EditPricesComponents/EditPricesLeaflet";
import EditPricesDiscountCard from "./EditPricesComponents/EditPricesDiscountCard";
import EditPricesPostcard from "./EditPricesComponents/EditPricesPostcard";
import {Context} from "../../../../index";

const EditConcretePrices = () => {
    const type = window.location.href.split("/")[5];
    const {global} = useContext(Context);

    global.setIsAdminPage(true);
    switch (type) {
        case 'businessCard':
            return <EditPricesBusinessCard/>
        case 'leaflet':
            return <EditPricesLeaflet/>
        case 'discountCard':
            return <EditPricesDiscountCard/>
        case 'postcard':
            return <EditPricesPostcard/>
    }
};

export default EditConcretePrices;