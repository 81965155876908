import React, {useContext} from 'react';
import styles from '../Calculation.module.css'
import {Context} from "../../../index";
import {Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import {observer} from "mobx-react-lite";

const LeafletBlockBw = observer(() => {
    const {leafletCalculation} = useContext(Context);
    const tooltip =
        <Tooltip id="tooltip" className='ms-2'>
            Минимальное кол-во листовок:
            <div>
                A3: 100
            </div>
            <div>
                A4: 200
            </div>
            <div>
                A5: 400
            </div>
            <div>
                A6: 800
            </div>
        </Tooltip>

    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            <div className={styles.group}>
                <div className={styles.label}>
                    Размер:
                </div>
                <div>
                    <Form.Select aria-label="Default select example" className={styles.selectInput}
                                 onChange={(e) => leafletCalculation.setCalculatingSizeBW(e.target.value)}
                                 value={leafletCalculation.calculatingSizeBW}>
                        <option value={'A3'} key={'A3'}>
                            Формат A3
                        </option>
                        <option value={'A4'} key={'A4'}>
                            Формат A4
                        </option>
                        <option value={'A5'} key={'A5'}>
                            Формат A5
                        </option>
                        <option value={'A6'} key={'A6'}>
                            Формат A6
                        </option>
                    </Form.Select>
                </div>
            </div>
            <div className={styles.group}>
                <div className={styles.label}>
                    Печать:
                </div>
                <div>
                    <span className={styles.choiceBlock}>
                        <input type="radio" name="pickerSide" value='printingOneSideA3BW' id='1'
                               checked={leafletCalculation.calculatingSidesBW === 'printingOneSideA3BW'}
                               onChange={(e) => leafletCalculation.setCalculatingSidesBW(e.target.value)}/>
                        <label htmlFor='1' className={styles.choice}>Односторонняя</label>
                    </span>
                    <span className={styles.choiceBlock}>
                        <input type="radio" name="pickerSide" value='printingTwoSidesA3BW' id='2'
                               checked={leafletCalculation.calculatingSidesBW === 'printingTwoSidesA3BW'}
                               onChange={(e) => leafletCalculation.setCalculatingSidesBW(e.target.value)}/>
                        <label htmlFor='2' className={styles.choice}>Двусторонняя</label>
                    </span>
                </div>
            </div>
            <div className={styles.group}>
                <div className={styles.label}>
                    Бумага:
                </div>
                <div>
                    <Form.Select aria-label="Default select example" className={styles.selectInput}
                                 onChange={(e) => leafletCalculation.setCalculatingPaperTypeBW(e.target.value)}
                                 value={leafletCalculation.calculatingPaperTypeBW}>
                        <option value={'paper65gA3'} key={'paper65gA3'}>
                            Бумага 65 грамм
                        </option>
                        <option value={'paper80gA3'} key={'paper80gA3'}>
                            Бумага 80 грамм
                        </option>
                        <option value={'paperNewspaperA3'} key={'paperNewspaperA3'}>
                            Газетная бумага
                        </option>
                        <option value={'paperWithColourA3'} key={'paperWithColourA3'}>
                            Цветная бумага
                        </option>
                    </Form.Select>
                </div>
            </div>
            <div className={styles.group}>
                <div className={styles.label}>
                    Количество:
                </div>
                <div>
                    <OverlayTrigger placement="right" overlay={tooltip}>
                        <Form.Control type="number" name="count" min={0}
                                      value={leafletCalculation.calculatingCountBW}
                                      className={styles.selectInput}
                                      onChange={(e) => leafletCalculation.setRealCalculatingCountBW(e.target.value)}
                                      onBlur={(e) => leafletCalculation.setCalculatingCountBW(e.target.value)}
                        />
                    </OverlayTrigger>
                </div>
            </div>
            <div className={styles.totalPriceBlock}>
                <div>
                    Итог: <span
                    className={styles.totalPrice}>{leafletCalculation.totalPriceBW}</span> руб.
                </div>
            </div>
            <div className={styles.contactUsBlock}>
                <div className={styles.contactUs}>
                    Заказать
                </div>
            </div>
        </Form>
    );
});

export default LeafletBlockBw;