import React, { useContext } from 'react';
import styles from './PostImagesContainer.module.css'
import { Carousel } from "react-bootstrap";
import { Context } from "../../index";

const PostImagesContainer = ({ index }) => {
    const { posts } = useContext(Context);
    const image1 = posts.posts[index].image1 ?? null;
    const image2 = posts.posts[index].image2 ?? null;
    const image3 = posts.posts[index].image3 ?? null;
    return (
        <Carousel indicators={false} autoPlay={false} controls={(image1 && image2) || (image1 && image3) || (image2 && image3)}>
            {
                image1 && (
                    <Carousel.Item>
                        <div className={styles.imageContainer}>
                            <img src={image1} alt='image' className={styles.bluredImage} />
                            <img src={image1} alt='image' className={styles.image} />
                        </div>
                    </Carousel.Item>
                )
            }
            {
                image2 && (
                    <Carousel.Item>
                        <div className={styles.imageContainer}>
                            <img src={image2} alt='image' className={styles.bluredImage} />
                            <img src={image2} alt='image' className={styles.image} />
                        </div>
                    </Carousel.Item>
                )
            }
            {
                image3 && (
                    <Carousel.Item>
                        <div className={styles.imageContainer}>
                            <img src={image3} alt='image' className={styles.bluredImage} />
                            <img src={image3} alt='image' className={styles.image} />
                        </div>
                    </Carousel.Item>
                )
            }
        </Carousel>
    );
};

export default PostImagesContainer;