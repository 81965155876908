import React, {useContext} from 'react';
import styles from './LastPost.module.css';
import convertDate from "../../utils/convertDate";
import {Card, Col, Row} from "react-bootstrap";
import changeText from "../../utils/changeText";
import {Context} from "../../index";

const LastPost = () => {
    const {posts} = useContext(Context);

    return (
        <div className={styles.newsCard}>
            <div className={styles.date}>{convertDate(posts.lastPost.created_at)}</div>
            <Row>
                <Col xl={3} lg={4} md={6} xs={12} className={styles.newsImageContainer}><img alt='post image' src={posts.lastPost.image1 ?? posts.lastPost.image2 ?? posts.lastPost.image3 ?? 'https://www.heavymart.com/youritem.png'} /></Col>
                <Col xl={9} lg={8} md={6} xs={12} className={styles.newsTextContainer}>
                    <div className={styles.newsHeader}>{posts.lastPost.header}</div>
                    {changeText(posts.lastPost.text)}
                </Col>
            </Row>
        </div>
    );
};

export default LastPost;