import React, {useContext, useEffect} from 'react';
import styles from './Login.module.css'
import Form from 'react-bootstrap/Form';
import {Button, Card, Container,} from "react-bootstrap";
import {observer} from 'mobx-react-lite';
import {Context} from '../../../index';
import {useNavigate} from "react-router-dom";
import {EDIT_BANNERS_ROUTE} from "../../../utils/const";

const Login = observer(() => {
    const {user} = useContext(Context);
    const {global} = useContext(Context);
    global.setIsAdminPage(true);

    const navigate = useNavigate();
    
    return (
        <Container>
            <Card className={styles.login}>
                <Form>
                    <div className="mb-3">
                        <Form.Group>
                            <Form.Label className={styles.label}>Логин</Form.Label>
                            <Form.Control
                                name="login"
                                id='login'
                                value={user.currentLogin}
                                onChange={(e) => user.setCurrentLogin(e.target.value)}
                                placeholder="Введите логин"
                            />
                        </Form.Group>
                    </div>
                    <div className="mb-3">
                        <Form.Group>
                            <Form.Label className={styles.label}>Пароль</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                id="password"
                                value={user.currentPassword}
                                onChange={(e) => user.setCurrentPassword(e.target.value)}
                                placeholder="Введите пароль"
                            />
                        </Form.Group>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        <Button className={styles.submitButton} type='submit' onClick={(e) => {
                            e.preventDefault();
                            user.auth().then(() => {
                                if (!user.error) navigate(EDIT_BANNERS_ROUTE);
                            });
                        }}>
                            Войти
                        </Button>
                    </div>
                </Form>
                {user.error && <div className={styles.error}>
                    {user.errorStatus}
                </div>}
            </Card>
        </Container>
    );
});

export default Login;