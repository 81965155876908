import React, {useContext, useEffect, useState} from 'react';
import styles from '../Calculation.module.css';
import {Context} from "../../../index";
import {Container, Spinner, Tab, Tabs} from "react-bootstrap";
import PostcardBlockWide from "./PostcardBlockWide";
import PostcardBlockNarrow from "./PostcardBlockNarrow";
import PostcardBlockFull from "./PostcardBlockFull";

const PostcardCalculation = () => {
    const {postcardCalculation} = useContext(Context);
    const [isPricesLoaded, setIsPricesLoaded] = useState(false);

    useEffect(() => {
        postcardCalculation.loadPrices().then(() => setIsPricesLoaded(true));
    }, []);

    const narrowTitleString = <span className={styles.tabLabel}>Складные по короткой стороне</span>;
    const wideTitleString = <span className={styles.tabLabel}>Складные по широкой стороне</span>;
    const fullTitleString = <span className={styles.tabLabel}>Полноразмерные</span>;

    return (
        <Container>
            {
                isPricesLoaded ?
                    <div className={styles.calculatorCard}>
                        <div className={styles.calculateHeader}>
                            Расчёт стоимости открыток "Евро"
                        </div>
                        <Tabs
                            defaultActiveKey="wide"
                            id="uncontrolled-tab-example"
                            className="mb-3 justify-content-between"
                        >
                            <Tab eventKey="wide" title={wideTitleString}>
                                <PostcardBlockWide/>
                            </Tab>
                            <Tab eventKey="narrow" title={narrowTitleString}>
                                <PostcardBlockNarrow/>
                            </Tab>
                            <Tab eventKey="full" title={fullTitleString} className='ms-auto'>
                                <PostcardBlockFull/>
                            </Tab>
                        </Tabs>
                    </div>
                    :
                    <Spinner className="spinner" animation={"grow"}/>
            }
        </Container>
    );
};

export default PostcardCalculation;