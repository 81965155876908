import {makeAutoObservable} from "mobx";
import {getPrice} from "../../http/pricesAPI";

export default class LeafletCalculationStore {
    constructor() {
        this._prices = {};
        this._error = false;
        this._calculatingSidesBW = 'printingOneSideA3BW';
        this._calculatingSidesColour = 'printingOneSideA3Color';
        this._calculatingPaperTypeColour = 'paper100gA3';
        this._calculatingPaperTypeBW = 'paper65gA3';
        this._calculatingSizeBW = 'A3';
        this._calculatingSizeColour = 'A3';
        this._calculatingCountBW = 0;
        this._calculatingCountColour = 0;
        this._totalPriceBW = 0;
        this._totalPriceColour = 0;
        this._previousCountBW = -1;
        this._previousCountColour = -1;
        makeAutoObservable(this);
    }

    setError(error) {
        this._error = error;
    }

    setPrices(prices) {
        this._prices = prices;
    }

    setCalculatingSidesBW(sides) {
        this._calculatingSidesBW = sides;
        this.calculatePriceBW();
    }

    setCalculatingSidesColour(sides) {
        this._calculatingSidesColour = sides;
        this.calculatePriceColour();
    }

    setCalculatingPaperTypeBW(type) {
        this._calculatingPaperTypeBW = type;
        this.calculatePriceBW();
    }

    setCalculatingPaperTypeColour(type) {
        this._calculatingPaperTypeColour = type;
        this.calculatePriceColour();
    }

    setCalculatingSizeBW(size) {
        this._calculatingSizeBW = size;
        this.setCalculatingCountBW(this.calculatingCountBW);
        this.calculatePriceBW();
    }

    setCalculatingSizeColour(size) {
        this._calculatingSizeColour = size;
        this.setCalculatingCountColour(this.calculatingCountColour);
        this.calculatePriceColour();
    }

    setCalculatingCountColour(count) {
        if (count <= 0) {
            this._calculatingCountColour = 0;
        } else if (this.calculatingSizeColour === 'A5') {
            if (count <= 2) {
                this._calculatingCountColour = 2;
            } else {
                this._calculatingCountColour = count;
            }
        } else if (this.calculatingSizeColour === 'A6') {
            if (count <= 4) {
                this._calculatingCountColour = 4;
            } else {
                this._calculatingCountColour = count;
            }
        } else {
            this._calculatingCountColour = count;
        }
        this.calculatePriceColour();
    }

    setCalculatingCountBW(count) {
        if (count <= 0) {
            this._calculatingCountBW = 0;
        } else if (this.calculatingSizeBW === 'A3') {
            if (count <= 100) {
                this._calculatingCountBW = 100;
            } else {
                this._calculatingCountBW = count;
            }
        } else if (this.calculatingSizeBW === 'A4') {
            if (count <= 200) {
                this._calculatingCountBW = 200;
            } else {
                this._calculatingCountBW = count;
            }
        } else if (this.calculatingSizeBW === 'A5') {
            if (count <= 400) {
                this._calculatingCountBW = 400;
            } else {
                this._calculatingCountBW = count;
            }
        } else if (this.calculatingSizeBW === 'A6') {
            if (count <= 800) {
                this._calculatingCountBW = 800;
            } else {
                this._calculatingCountBW = count;
            }
        } else {
            this._calculatingCountBW = count;
        }
        this.calculatePriceBW();
    }

    setRealCalculatingCountColour(count) {
        this._calculatingCountColour = count;
    }

    setRealCalculatingCountBW(count) {
        this._calculatingCountBW = count;
    }

    setTotalPriceBW(price) {
        if (Math.floor(price) !== price) {
            price = price.toFixed(2)
        }
        this._totalPriceBW = price;
    }

    setTotalPriceColour(price) {
        this._totalPriceColour = price;
    }

    setPreviousCountBW(count) {
        this._previousCountBW = count;
    }

    setPreviousCountColour(count) {
        this._previousCountColour = count;
    }

    get error() {
        return this._error;
    }

    get prices() {
        return this._prices;
    }

    get calculatingSidesBW() {
        return this._calculatingSidesBW;
    }

    get calculatingSidesColour() {
        return this._calculatingSidesColour;
    }

    get calculatingPaperTypeBW() {
        return this._calculatingPaperTypeBW;
    }

    get calculatingPaperTypeColour() {
        return this._calculatingPaperTypeColour;
    }

    get calculatingSizeBW() {
        return this._calculatingSizeBW;
    }

    get calculatingSizeColour() {
        return this._calculatingSizeColour;
    }

    get calculatingCountBW() {
        return this._calculatingCountBW;
    }

    get calculatingCountColour() {
        return this._calculatingCountColour;
    }

    get totalPriceBW() {
        return this._totalPriceBW;
    }

    get totalPriceColour() {
        return this._totalPriceColour;
    }

    get previousCountBW() {
        return this._previousCountBW;
    }

    get previousCountColour() {
        return this._previousCountColour;
    }

    async loadPrices() {
        try {
            const prices = await getPrice('leaflet');
            this.setPrices(prices);
            this.setError(false);
        } catch (e) {
            this.setError(true);
        }
    }

    getPriceColour(coefficient) {
        return (this.prices[this.calculatingPaperTypeColour] + this.prices[this.calculatingSidesColour]) * Math.ceil(this.calculatingCountColour / coefficient)
    }

    getPriceBW(coefficient) {
        let price = (this.prices[this.calculatingPaperTypeBW] + this.prices[this.calculatingSidesBW]) * Math.ceil(this.calculatingCountBW / coefficient);
        price += (this.calculatingSidesBW === 'printingOneSideA3BW') ? 100 : 200;
        return price;
    }

    calculatePriceColour() {
        switch (this.calculatingSizeColour) {
            case 'A3':
                this.setTotalPriceColour(this.getPriceColour(1));
                break;
            case 'A4':
                this.setTotalPriceColour(this.getPriceColour(2));
                break;
            case 'A5':
                this.setTotalPriceColour(this.getPriceColour(4));
                break;
            case 'A6':
                this.setTotalPriceColour(this.getPriceColour(8));
                break;
        }
    }

    calculatePriceBW() {
        switch (this.calculatingSizeBW) {
            case 'A3':
                this.setTotalPriceBW(this.getPriceBW(1));
                break;
            case 'A4':
                this.setTotalPriceBW(this.getPriceBW(2));
                break;
            case 'A5':
                this.setTotalPriceBW(this.getPriceBW(4));
                break;
            case 'A6':
                this.setTotalPriceBW(this.getPriceBW(8));
                break;
        }
    }
}