import React from 'react';
import styles from './DesignEditorElement.module.css'
import {useNavigate} from "react-router-dom";
import {CREATE_DESIGN_ROUTE} from "../../utils/const";

const DesignEditorElement = ({name, image, route}) => {
    const navigate = useNavigate();

    return (
        <div className={styles.designCard} onClick={() => {
            navigate(CREATE_DESIGN_ROUTE + route);
        }}>
            <img src={image} alt={name} className={styles.designImage}/>
            <div className={styles.designCardHeader}>
                {name}
            </div>
        </div>
    );
};

export default DesignEditorElement;