import React, {useContext, useEffect} from 'react';
import styles from './contactUsWithFile.module.css';
import Modal from "react-bootstrap/Modal";
import {observer} from "mobx-react-lite";
import {Button, Form} from "react-bootstrap";
import {Context} from "../../../index";
import swal from "sweetalert";

const ContactUsWithFile = observer(({show, onHide, type}) => {
    const {contacts} = useContext(Context);
    useEffect(() => contacts.setTypeText(type), []);

    return (
        <Modal show={show} onHide={onHide}
               aria-labelledby="contained-modal-title-vcenter"
               centered>
            <Modal.Header closeButton>
                <Modal.Title>{'Отправить файл'}</Modal.Title>
            </Modal.Header>
            <form onSubmit={(event) => {
                event.preventDefault();
                onHide();
            }}>
                <Modal.Body>
                    <div className={styles.inputBlock}>
                        <Form.Control required placeholder='Имя' value={contacts.name}
                                      onChange={(e) => contacts.setName(e.target.value)}/>
                    </div>
                    <div className={styles.inputBlock}>
                        <Form.Control required placeholder='E-mail' type='email' value={contacts.email}
                                      onChange={(e) => contacts.setEmail(e.target.value)}/>
                    </div>
                    <div className={styles.inputBlock}>
                        <Form.Control placeholder="Текст сообщения" as="textarea" rows={3} value={contacts.text}
                                      onChange={(e) => contacts.setText(e.target.value)}/>
                    </div>
                    <div className={styles.fileBlock}>
                        <div className={styles.fileHeader}>
                            Файл:
                        </div>
                        <div className={styles.fileName}>
                            user_file.png
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className={`${styles.closeModalButton} ${styles.button}`} onClick={onHide}>
                        Закрыть
                    </button>
                    <button className={`${styles.sendButton} ${styles.button}`} onClick={() => {
                        contacts.confirmSending().then(() => {
                            swal("Успешно!", "Письмо отправлено", "success", {
                                buttons: false,
                            }).then(() => contacts.clearAllFields());
                        }).finally(onHide());
                    }} type='submit'>
                        Отправить
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
});

export default ContactUsWithFile;