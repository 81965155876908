import {$authHost, $host} from "./index";

export const login = async (login, password) => {
    const {data} = await $host.post('auth/login', {login, password});
    return data;
}

export const logout = async () => {
    const {data} = await $authHost.post('auth/logout');
    return data;
}

export const checkAuthFromToken = async () => {
    const {data} = await $authHost.get('auth/check_authorization');
    return data;
}