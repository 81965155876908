import React, {useEffect} from 'react';
import styles from './PaginationBlock.module.css'
import {Pagination} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";

const PaginationBlock = observer(({route, count, current, setPageNumber}) => {
    const navigate = useNavigate();

    const pagIndexes = Array.from({length: count}, function (value, index) {
        return index;
    })

    const getNewPage = (number) => {
        setPageNumber(number);
        navigate(route + '?page=' + number);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'
        });
    }

    const createPagination = () => {
        return pagIndexes.map((index) => {
            if (index + 1 === current) {
                return (
                    <Pagination.Item active className={styles.paginationItem} key={index}>{index + 1}</Pagination.Item>
                );
            } else {
                return (
                    <Pagination.Item className={styles.paginationItem} onClick={() => getNewPage(index+1)} key={index}>{index + 1}
                    </Pagination.Item>
                );
            }
        })
    }

    return (
        <Pagination>
            <Pagination.Prev disabled={current === 1} className={styles.paginationItem} onClick={() => getNewPage(current-1)}/>
            {createPagination()}
            <Pagination.Next disabled={current === count} className={styles.paginationItem} onClick={() => getNewPage(current+1)}/>
        </Pagination>
    );
});

export default PaginationBlock;