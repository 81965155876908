import React, {useContext} from 'react';
import stylesSample2 from './BusinessCardSample2.module.css'
import {observer} from "mobx-react-lite";
import {Context} from "../../../../../index";
import styles from "../BusinessCard.module.css";

const BusinessCardSample2 = observer(() => {
    const {businessCard} = useContext(Context);

    return (
        <div className={styles.businessCard}>
            <div id='businessCardContent'>
                <div className={`${stylesSample2.background} ${styles.padding}`}>
                    <div className={styles.content}>
                        <div className={stylesSample2.mainInf}>
                            <div className={stylesSample2.name}>
                                {businessCard.name}
                            </div>
                            <div className={stylesSample2.jobTitle}>
                                {businessCard.jobTitle}
                            </div>
                        </div>
                        <div className={stylesSample2.informationBlock}>
                            <div className={stylesSample2.header}>
                                {businessCard.header}
                            </div>
                            <div>
                                {businessCard.address}
                            </div>
                            <div>
                                {businessCard.phone}
                            </div>
                            <div>
                                {businessCard.email}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default BusinessCardSample2;