import React, {useContext, useEffect, useState} from 'react';
import {Container, Spinner} from "react-bootstrap";
import styles from './EditPricesComponents.module.css'
import EditPricesField from "./EditPricesField";
import {Context} from "../../../../../index";
import {observer} from "mobx-react-lite";

const EditPricesDiscountCard = observer(() => {
    const {discountCardCalculation} = useContext(Context);
    const [isPricesLoaded, setIsPricesLoaded] = useState(false);

    useEffect(() => {
        discountCardCalculation.loadPrices().then(() => setIsPricesLoaded(true));
    }, []);

    return (
        <>
            {isPricesLoaded ?
                <Container>
                    <div className={styles.priceEditorHeader}>
                        Изменение цен для дискотных карт
                    </div>
                    <div className={styles.pricesBlock}>
                        <EditPricesField text='Матовая ламинация' type='matteLamination'
                                         price={discountCardCalculation.prices['matteLamination']} itemType='discountCard'/>
                        <EditPricesField text='Глянцевая ламинация' type='glossyLamination'
                                         price={discountCardCalculation.prices['glossyLamination']} itemType='discountCard'/>
                        <EditPricesField text='Печать' type='printing'
                                         price={discountCardCalculation.prices['printing']} itemType='discountCard'/>
                    </div>
                </Container>
                :
                <Spinner className="spinner" animation={"grow"}/>}
        </>
    );
});

export default EditPricesDiscountCard;