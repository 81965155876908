import {makeAutoObservable} from "mobx";

export default class PinStore {
    constructor() {
        this._header = 'Название вашей компании';
        this._phone = '777-765';
        this._image = '';
        makeAutoObservable(this);
    }


    setHeader(header) {
        this._header = header;
    }
    setPhone(phone) {
        this._phone = phone;
    }
    setImage(file) {
        this._image = file;
    }

    get header() {
        return this._header;
    }
    get phone() {
        return this._phone;
    }
    get image() {
        return this._image;
    }
}