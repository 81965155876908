import React, {useContext, useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import NavigationPanel from "./components/NavigationPanel/NavigationPanel";
import {Context} from "./index";
import {Spinner} from "react-bootstrap";

const App = () => {
    const [loading, setLoading] = useState(true);
    const {user} = useContext(Context);

    useEffect(() => {
        user.checkAuth().then(() => setLoading(false))
    }, []);

    if (loading) {
        return <Spinner className="spinner" animation={"grow"}/>
    }

    return (
        <BrowserRouter>
            <NavigationPanel/>
            <AppRouter/>
        </BrowserRouter>
    );
};

export default App;