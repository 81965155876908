import React, {useContext, useEffect, useState} from 'react';
import {Container, Spinner} from "react-bootstrap";
import styles from './EditPricesComponents.module.css'
import EditPricesField from "./EditPricesField";
import {Context} from "../../../../../index";
import {observer} from "mobx-react-lite";

const EditPricesLeaflet = observer(() => {
    const {leafletCalculation} = useContext(Context);
    const [isPricesLoaded, setIsPricesLoaded] = useState(false);

    useEffect(() => {
        leafletCalculation.loadPrices().then(() => setIsPricesLoaded(true));
    }, []);

    return (
        <>
            {isPricesLoaded ?
                <Container>
                    <div className={styles.priceEditorHeader}>
                        Изменение цен для листовок
                    </div>
                    <div className={styles.pricesBlock}>
                        <EditPricesField text='Бумага цветная' type='paperWithColourA3'
                                         price={leafletCalculation.prices['paperWithColourA3']} itemType='leaflet'/>
                        <EditPricesField text='Бумага газетная' type='paperNewspaperA3'
                                         price={leafletCalculation.prices['paperNewspaperA3']} itemType='leaflet'/>
                        <EditPricesField text='Бумага А3 65 грамм' type='paper65gA3'
                                         price={leafletCalculation.prices['paper65gA3']} itemType='leaflet'/>
                        <EditPricesField text='Бумага А3 80 грамм' type='paper80gA3'
                                         price={leafletCalculation.prices['paper80gA3']} itemType='leaflet'/>
                        <EditPricesField text='Бумага А3 100 грамм' type='paper100gA3'
                                         price={leafletCalculation.prices['paper100gA3']} itemType='leaflet'/>
                        <EditPricesField text='Бумага А3 115 грамм' type='paper115gA3'
                                         price={leafletCalculation.prices['paper115gA3']} itemType='leaflet'/>
                        <EditPricesField text='Бумага А3 130 грамм' type='paper130gA3'
                                         price={leafletCalculation.prices['paper130gA3']} itemType='leaflet'/>
                        <EditPricesField text='Бумага А3 160 грамм' type='paper160gA3'
                                         price={leafletCalculation.prices['paper160gA3']} itemType='leaflet'/>
                        <EditPricesField text='Цветная печать на одной стороне' type='printingOneSideA3Color'
                                         price={leafletCalculation.prices['printingOneSideA3Color']} itemType='leaflet'/>
                        <EditPricesField text='Цветная печать на двух сторонах' type='printingTwoSidesA3Color'
                                         price={leafletCalculation.prices['printingTwoSidesA3Color']} itemType='leaflet'/>
                        <EditPricesField text='Ч/Б печать на одной стороне' type='printingOneSideA3BW'
                                         price={leafletCalculation.prices['printingOneSideA3BW']} itemType='leaflet'/>
                        <EditPricesField text='Ч/Б на двух сторонах' type='printingTwoSidesA3BW'
                                         price={leafletCalculation.prices['printingTwoSidesA3BW']} itemType='leaflet'/>
                        <EditPricesField text='Мастер-модель 1 сторона' type='masterOneSide'
                                         price={leafletCalculation.prices['masterOneSide']} itemType='leaflet'/>
                        <EditPricesField text='Мастер-модель 2 стороны' type='masterTwoSides'
                                         price={leafletCalculation.prices['masterTwoSides']} itemType='leaflet'/>
                    </div>
                </Container>
                :
                <Spinner className="spinner" animation={"grow"}/>}
        </>
    );
});

export default EditPricesLeaflet;