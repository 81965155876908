import { makeAutoObservable } from "mobx";
import {checkAuthFromToken, login} from "../http/userAPI"

export default class UserStore {
    constructor() {
        this._isAuth = false;
        this._currentLogin = '';
        this._currentPassword = '';
        this._error = false;
        this._errorMessage = '';
        makeAutoObservable(this);
    }

    setIsAuth(status) {
        this._isAuth = status;
    }

    setCurrentLogin(login) {
        this._currentLogin = login;
    }

    setCurrentPassword(password) {
        this._currentPassword = password;
    }

    setError(error) {
        this._error = error;
    }

    setErrorMessage(errorMessage) {
        this._errorMessage = errorMessage;
    }

    get isAuth() {
        return this._isAuth;
    }

    get currentLogin() {
        return this._currentLogin;
    }

    get currentPassword() {
        return this._currentPassword;
    }

    get error() {
        return this._error;
    }

    get errorStatus() {
        return this._errorMessage;
    }

    async auth() {
        if (this.currentLogin.length === 0 || this.currentPassword.length === 0) {
            this.setError(true);
            this.setErrorMessage('Оба поля должны быть заполнены');
            return;
        }
        try {
            const data = await login(this.currentLogin, this.currentPassword);
            localStorage.setItem('token', data.token);
            this.setIsAuth(true);
            this.setError(false);
            this.setCurrentLogin('');
            this.setCurrentPassword('');
        } catch (e) {
            this.setError(true);
            switch (e.response.data.message) {
                case 'Wrong login':
                    this.setErrorMessage('Неверный логин!');
                    return;
                case 'Wrong password':
                    this.setErrorMessage('Неверный пароль!');
                    return;
                default:
                    this.setErrorMessage('Ошибка авторизации!');
                    return;
            }
        }
    }

    authOut() {
        this.setIsAuth(false);
        localStorage.removeItem('token');
    }

    async checkAuth() {
        const token = localStorage.getItem('token');
        if (token === null || token === '') {
            this.setIsAuth(false);
        } else {
            try {
                await checkAuthFromToken();
                this.setIsAuth(true);
            } catch (e) {
                this.setIsAuth(false)
            }
        }
    }
}