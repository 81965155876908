import React from 'react';
import styles from './DesignEditorLeaflet.module.css'

const DesignEditorLeaflet = () => {
    return (
        <div>
            листовка
        </div>
    );
};

export default DesignEditorLeaflet;