import React from 'react';
import styles from './ContactUs.module.css'

const ContactUs = ({text, color, handler}) => {
    return (
        <div>
            <div className={`${styles.contactUsBlock} ${(color === 'yellow') ? styles.yellow : styles.blue }`} onClick={() => handler()}>
                {text}
            </div>
        </div>
    );
};

export default ContactUs;