import React, {useContext, useEffect, useState} from 'react';
import styles from '../Calculation.module.css'
import {Context} from "../../../index";
import {Container, Spinner, Tab, Tabs} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import LeafletBlockBW from "./LeafletBlockBW";
import LeafletBlockColour from "./LeafletBlockColour";

const LeafletCalculation = observer(() => {
    const {leafletCalculation} = useContext(Context);
    const [isPricesLoaded, setIsPricesLoaded] = useState(false);

    useEffect(() => {
        leafletCalculation.loadPrices().then(() => setIsPricesLoaded(true));
    }, []);

    const colourTitleString = <span className={styles.tabLabel}>Цветные</span>;
    const bwTitleString = <span className={styles.tabLabel}>Чернобелые</span>;
    return (
        <Container>
            {
                isPricesLoaded ?
                    <div className={styles.calculatorCard}>
                        <div className={styles.calculateHeader}>
                            Расчёт стоимости листовок
                        </div>
                        <Tabs
                            defaultActiveKey="colour"
                            id="uncontrolled-tab-example"
                            className="mb-3 justify-content-between"
                        >
                            <Tab eventKey="colour" title={colourTitleString}>
                                <LeafletBlockColour/>
                            </Tab>
                            <Tab eventKey="bw" title={bwTitleString}>
                                <LeafletBlockBW/>
                            </Tab>
                        </Tabs>
                    </div>

                    :
                    <Spinner className="spinner" animation={"grow"}/>
            }
        </Container>
    );
});

export default LeafletCalculation;