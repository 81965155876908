import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../../../index";
import styles from "./Pin.module.css";
import image from '../../../../images/vk.png'
const Pin = observer(() => {
    const {pin} = useContext(Context);

    return (
        <div className={styles.pin}>
            <div id='pinContent'>
                <div className={styles.padding}>
                    <div className={styles.content}>
                        <div className={styles.pinHeader}>
                            {pin.header}
                        </div>
                        <div className={styles.pinPhone}>
                            {pin.phone}
                        </div>
                        <img src={pin.image} alt={''} className={styles.background}/>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default Pin;