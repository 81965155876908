import {makeAutoObservable} from "mobx";
import {getPrice} from "../../http/pricesAPI";

export default class PostcardCalculationStore {
    constructor() {
        this._prices = {};
        this._error = false;
        this._calculatingPaperTypeWide = 'widePaper300g';
        this._calculatingPaperTypeNarrow = 'narrowPaper300g';
        this._calculatingPrintingType = 'full4Plus1Paper';
        this._calculatingCountWide = 0;
        this._calculatingCountNarrow = 0;
        this._calculatingCountFull = 0;
        this._previousCountWide = -1;
        this._previousCountNarrow = -1;
        this._previousCountFull = -1;
        this._totalPriceWide = 0;
        this._totalPriceNarrow = 0;
        this._totalPriceFull = 0;
        makeAutoObservable(this);
    }

    setPrices(prices) {
        this._prices = prices;
    }

    setError(error) {
        this._error = error;
    }

    setCalculatingPaperTypeWide(type) {
        this._calculatingPaperTypeWide = type;
        this.calculatePriceWide();
    }

    setCalculatingPaperTypeNarrow(type) {
        this._calculatingPaperTypeNarrow = type;
        this.calculatePriceNarrow();
    }

    setCalculatingPrintingType(type) {
        this._calculatingPrintingType = type;
        this.calculatePriceFull();
    }

    setCalculatingCountWide(count) {
        this.setPreviousCountWide(this.calculatingCountWide);
        if (count <= 0) {
            this._calculatingCountWide = 0;
        } else if (count < this.previousCountWide) {
            this._calculatingCountWide = (3 * Math.floor(count / 3));
        } else {
            this._calculatingCountWide = (3 * Math.ceil(count / 3));
        }
        this.calculatePriceWide();
    }

    setCalculatingCountNarrow(count) {
        this.setPreviousCountNarrow(this.calculatingCountNarrow);
        if (count <= 0) {
            this._calculatingCountNarrow = 0;
        } else if (count < this.previousCountNarrow) {
            this._calculatingCountNarrow = (3 * Math.floor(count / 3));
        } else {
            this._calculatingCountNarrow = (3 * Math.ceil(count / 3));
        }
        this.calculatePriceNarrow();
    }

    setCalculatingCountFull(count) {
        this.setPreviousCountFull(this.calculatingCountFull);
        if (count <= 0) {
            this._calculatingCountFull = 0;
        } else if (count < this.previousCountFull) {
            this._calculatingCountFull = (3 * Math.floor(count / 3));
        } else {
            this._calculatingCountFull = (3 * Math.ceil(count / 3));
        }
        this.calculatePriceFull();
    }

    setPreviousCountWide(count) {
        this._previousCountWide = count;
    }

    setPreviousCountNarrow(count) {
        this._previousCountNarrow = count;
    }

    setPreviousCountFull(count) {
        this._previousCountFull = count;
    }

    setTotalPriceWide(price) {
        this._totalPriceWide = price;
    }

    setTotalPriceNarrow(price) {
        this._totalPriceNarrow = price;
    }

    setTotalPriceFull(price) {
        this._totalPriceFull = price;
    }

    setRealCalculatingCountWide(price) {
        this._calculatingCountWide = price;
    }

    setRealCalculatingCountNarrow(price) {
        this._calculatingCountNarrow = price;
    }

    setRealCalculatingCountFull(price) {
        this._calculatingCountFull = price;
    }

    get prices() {
        return this._prices;
    }

    get error() {
        return this._error;
    }

    get calculatingPaperTypeWide() {
        return this._calculatingPaperTypeWide;
    }

    get calculatingPaperTypeNarrow() {
        return this._calculatingPaperTypeNarrow;
    }

    get calculatingPrintingType() {
        return this._calculatingPrintingType;
    }

    get calculatingCountWide() {
        return this._calculatingCountWide;
    }

    get calculatingCountNarrow() {
        return this._calculatingCountNarrow;
    }

    get calculatingCountFull() {
        return this._calculatingCountFull;
    }

    get previousCountWide() {
        return this._previousCountWide;
    }

    get previousCountNarrow() {
        return this._previousCountNarrow;
    }

    get previousCountFull() {
        return this._previousCountFull;
    }

    get totalPriceWide() {
        return this._totalPriceWide;
    }

    get totalPriceNarrow() {
        return this._totalPriceNarrow;
    }

    get totalPriceFull() {
        return this._totalPriceFull;
    }

    async loadPrices() {
        try {
            const prices = await getPrice('postcard');
            this.setPrices(prices);
            this.setError(false);
        } catch (e) {
            this.setError(true);
        }
    }

    calculatePriceWide() {
        this.setTotalPriceWide((this.prices[this.calculatingPaperTypeWide] + this.prices['narrowAndWideCutting']) * this.calculatingCountWide);
    }

    calculatePriceNarrow() {
        this.setTotalPriceNarrow((this.prices[this.calculatingPaperTypeNarrow] + this.prices['narrowAndWideCutting']) * this.calculatingCountNarrow);
    }

    calculatePriceFull() {
        this.setTotalPriceFull((this.prices[this.calculatingPrintingType] + this.prices['fullCutting']) * this.calculatingCountFull);
    }
}