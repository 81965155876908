import React from 'react';
import styles from './WhyMeGroup.module.css'
import {Col, Row} from "react-bootstrap";
import WhyMeElement from "../WhyMeElement/WhyMeElement";
import highQualityImage from '../../images/mainPageIcons/highQuality.png'
import timeImage from '../../images/mainPageIcons/time.png'
import moneyImage from '../../images/mainPageIcons/money.png'
import anyCountImage from '../../images/mainPageIcons/anyCount.png'

const WhyMeGroup = () => {
    return (
        <>
            <Row>
                <Col lg={3} md={3} sm={6} xs={6}>
                    <WhyMeElement text={'Высокое качество продукции'}
                                  image={highQualityImage}/>
                </Col>
                <Col lg={3} md={3} sm={6} xs={6}>
                    <WhyMeElement text={'Скорость выполнения'}
                                  image={timeImage}/>
                </Col>
                <Col lg={3} md={3} sm={6} xs={6}>
                    <WhyMeElement text={'Выгодные и честные цены'}
                                  image={moneyImage}/>
                </Col>
                <Col lg={3} md={3} sm={6} xs={6}>
                    <WhyMeElement text={'Тираж любого размера'}
                                  image={anyCountImage}/>
                </Col>
            </Row>
        </>
    );
};

export default WhyMeGroup;