import React, {useContext, useEffect, useState} from 'react';
import {Container, Spinner} from "react-bootstrap";
import styles from './EditPricesComponents.module.css'
import EditPricesField from "./EditPricesField";
import {Context} from "../../../../../index";
import {observer} from "mobx-react-lite";

const EditPricesPostcard = observer(() => {
    const {postcardCalculation} = useContext(Context);
    const [isPricesLoaded, setIsPricesLoaded] = useState(false);

    useEffect(() => {
        postcardCalculation.loadPrices().then(() => setIsPricesLoaded(true));
    }, []);

    return (
        <>
            {isPricesLoaded ?
                <Container>
                    <div className={styles.priceEditorHeader}>
                        Изменение цен для открыток "Евро"
                    </div>
                    <div className={styles.pricesBlock}>
                        <EditPricesField text='Бумага 300 грамм (сгиб по д/с)' type='widePaper300g'
                                         price={postcardCalculation.prices['widePaper300g']} itemType='postcard'/>
                        <EditPricesField text='Бумага дизайнерская 1-го типа (сгиб по д/с)' type='wideDesignPaper1'
                                         price={postcardCalculation.prices['wideDesignPaper1']} itemType='postcard'/>
                        <EditPricesField text='Бумага дизайнерская 2-го типа (сгиб по д/с)' type='wideDesignPaper2'
                                         price={postcardCalculation.prices['wideDesignPaper2']} itemType='postcard'/>
                        <EditPricesField text='Бумага дизайнерская 3-го типа (сгиб по д/с)' type='wideDesignPaper3'
                                         price={postcardCalculation.prices['wideDesignPaper3']} itemType='postcard'/>
                        <EditPricesField text='Резка, биговка (сгибы по д/с, к/с)' type='narrowAndWideCutting'
                                         price={postcardCalculation.prices['narrowAndWideCutting']} itemType='postcard'/>
                        <EditPricesField text='Бумага 300 грамм (сгиб по к/с)' type='narrowPaper300g'
                                         price={postcardCalculation.prices['narrowPaper300g']} itemType='postcard'/>
                        <EditPricesField text='Бумага дизайнерская 1-го типа (сгиб по к/с)' type='narrowDesignPaper1'
                                         price={postcardCalculation.prices['narrowDesignPaper1']} itemType='postcard'/>
                        <EditPricesField text='Бумага дизайнерская 2-го типа (сгиб по к/с)' type='narrowDesignPaper2'
                                         price={postcardCalculation.prices['narrowDesignPaper2']} itemType='postcard'/>
                        <EditPricesField text='Бумага дизайнерская 3-го типа (сгиб по к/с)' type='narrowDesignPaper3'
                                         price={postcardCalculation.prices['narrowDesignPaper3']} itemType='postcard'/>
                        <EditPricesField text='Печать 4+1 (полный размер)' type='full4Plus1Paper'
                                         price={postcardCalculation.prices['full4Plus1Paper']} itemType='postcard'/>
                        <EditPricesField text='Печать 4+4 (полный размер)' type='full4Plus4Paper'
                                         price={postcardCalculation.prices['full4Plus4Paper']} itemType='postcard'/>
                        <EditPricesField text='Резка (полный размер)' type='fullCutting'
                                         price={postcardCalculation.prices['fullCutting']} itemType='postcard'/>
                    </div>
                </Container>
                :
                <Spinner className="spinner" animation={"grow"}/>}
        </>
    );
});

export default EditPricesPostcard;