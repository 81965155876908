import { makeAutoObservable } from "mobx";

export default class GlobalStore {
    constructor() {
        this._isAdminPage = false;
        makeAutoObservable(this);
    }

    setIsAdminPage(status) {
        this._isAdminPage = status;
    }

    get isAdminPage() {
        return this._isAdminPage;
    }
}