import React, {useContext} from 'react';
import styles from './CreateDesign.module.css'
import DesignEditorBusinessCard from "../../components/DesignEditors/DesignEditorBusinessCard/DesignEditorBusinessCard";
import DesignEditorPin from "../../components/DesignEditors/DesignEditorPin/DesignEditorPin";
import DesignEditorTable from "../../components/DesignEditors/DesignEditorTable/DesignEditorTable";
import DesignEditorLeaflet from "../../components/DesignEditors/DesignEditorLeaflet/DesignEditorLeaflet";
import {Container} from "react-bootstrap";
import {Context} from "../../index";

const CreateDesign = ({header}) => {
    const {global} = useContext(Context);
    global.setIsAdminPage(false);

    return (
        <Container>
            <div className={styles.header}>
                {header}
            </div>
            <div>
                {
                    (header === 'Создать дизайн визитки') && <DesignEditorBusinessCard/>
                }
                {
                    (header === 'Создать дизайн значка') && <DesignEditorPin/>
                }
                {
                    (header === 'Создать дизайн кабинетной таблички') && <DesignEditorTable/>
                }
                {
                    (header === 'Создать дизайн листовки') && <DesignEditorLeaflet/>
                }
            </div>
        </Container>
    );
};

export default CreateDesign;