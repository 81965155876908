import React, {useContext} from 'react';
import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
import {Context} from "../../../index";

const ModalCreateAndUpdateBanner = ({show, onHide, type}) => {
    const {banners} = useContext(Context);

    return (
        <Modal show={show} onHide={onHide}
               aria-labelledby="contained-modal-title-vcenter"
               centered>
            <Modal.Header closeButton>
                <Modal.Title>{type === 'create' ? 'Добавление баннера' : 'Изменение баннера'}</Modal.Title>
            </Modal.Header>
            <form onSubmit={(event) => {
                event.preventDefault();
                switch (type) {
                    case 'create':
                        banners.createBanner().then(() => onHide());
                        break;
                    case 'update':
                        banners.updateBanner().then(() => onHide());
                }
                onHide();
            }}>
                <Modal.Body>
                    <input type="file" className="form-control" id="customFile" name='image'/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        Закрыть
                    </Button>
                    <Button variant="primary" type='submit'>
                        {type === 'create' ? 'Создать' : 'Изменить'}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default ModalCreateAndUpdateBanner;