import { $authHost, $host } from "./index";
import axios from "axios";
import {BASE_URL} from "../utils/const";

export const getAllPosts = async (page = 1) => {
    const { data } = await $host.get('post' + '?page=' + page);
    return data;
}

export const dropPost = async (id) => {
    const {data} = await $authHost.delete(`post/${id}`);
    return data;
}

export const storePost = async (header, text) => {
    const formData = new FormData();
    const file1 = document.getElementById('image1');
    const file2 = document.getElementById('image2');
    const file3 = document.getElementById('image3');
    if (file1.files[0]) formData.append('image1', file1.files[0]);
    if (file2.files[0]) formData.append('image2', file2.files[0]);
    if (file3.files[0]) formData.append('image3', file3.files[0]);
    formData.append('header', header);
    formData.append('text', text);
    await axios.post(BASE_URL + 'post', formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
        },
    })
}

export const getLastPost = async () => {
    const {data} = await $host.get('post/last_post');
    return data;
}