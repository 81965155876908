import React, {useContext} from 'react';
import {Context} from "../../../index";
import {Col, Container, Row} from "react-bootstrap";
import styles from "../../CalculatorsList/CalculatorsList.module.css";
import EditPricesListElement from "../../../components/EditPricesListElement/EditPricesListElement";

const EditPrices = () => {
    const {global} = useContext(Context);
    global.setIsAdminPage(true);

    return (
        <Container>
            <div className={styles.calculatorListHeader}>
                Выберите категорию для изменения цен
            </div>
            <div>
                <Row>
                    <Col xs={6} sm={4}>
                        <EditPricesListElement text='Визитки' type='businessCard'/>
                    </Col>
                    <Col xs={6} sm={4}>
                        <EditPricesListElement text='Листовки' type='leaflet'/>
                    </Col>
                    <Col xs={6} sm={4}>
                        <EditPricesListElement text='Дисконтные карты' type='discountCard'/>
                    </Col>
                    <Col xs={6} sm={4}>
                        <EditPricesListElement text='Открытки "Евро"' type='postcard'/>
                    </Col>
                    <Col xs={6} sm={4}>
                        <EditPricesListElement text='Таблички' type='table'/>
                    </Col>
                    <Col xs={6} sm={4}>
                        <EditPricesListElement text='Шильды' type='shield'/>
                    </Col>
                    <Col xs={6} sm={4}>
                        <EditPricesListElement text='Плакаты' type='poster'/>
                    </Col>
                    <Col xs={6} sm={4}>
                        <EditPricesListElement text='Чертежи' type='drawing'/>
                    </Col>
                    <Col xs={6} sm={4}>
                        <EditPricesListElement text='Стенды' type='stand'/>
                    </Col>
                    <Col xs={12} sm={12}>
                        <EditPricesListElement text='Баннеры' type='banner'/>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default EditPrices;