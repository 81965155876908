import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import UserStore from "./store/UserStore";
import GlobalStore from "./store/GlobalStore";
import BannersStore from "./store/BannersStore";
import PostsStore from './store/PostsStore';
import BusinessCardStore from "./store/BusinessCardStore";
import BusinessCardCalculationStore from "./store/CalculationStores/BusinessCardCalculationStore";
import DiscountCardCalculationStore from "./store/CalculationStores/DiscountCardCalculationStore";
import LeafletCalculationStore from "./store/CalculationStores/LeafletCalculationStore";
import PostcardCalculationStore from "./store/CalculationStores/PostcardCalculationStore";
import ContactStore from "./store/ContactStore";
import PinStore from "./store/PinStore";
export const Context = createContext(null);

ReactDOM.render(
    <Context.Provider value={{
        user: new UserStore(),
        global: new GlobalStore(),
        banners: new BannersStore(),
        posts: new PostsStore(),
        businessCard: new BusinessCardStore(),
        pin: new PinStore(),
        businessCardCalculation: new BusinessCardCalculationStore(),
        discountCardCalculation: new DiscountCardCalculationStore(),
        leafletCalculation: new LeafletCalculationStore(),
        postcardCalculation: new PostcardCalculationStore(),
        contacts: new ContactStore()
    }}>
        <App />
    </Context.Provider>,
    document.getElementById('root')
);
