import React, {useContext} from 'react';
import styles from './BusinessCard.module.css'
import stylesSample1 from './samples/BusinessCardSample1.module.css'
import imageSample1 from '../../../../images/background.png'
import BusinessCardSample1 from "./samples/BusinessCardSample1";
import {Context} from "../../../../index";
import {observer} from "mobx-react-lite";
import BusinessCardSample2 from "./samples/BusinessCardSample2";
import BusinessCardSample3 from "./samples/BusinessCardSample3";
import BusinessCardSample4 from "./samples/BusinessCardSample4";

const BusinessCard = observer(() => {
    const {businessCard} = useContext(Context);

    switch (businessCard.currentSample) {
        case 1:
            return (
                <BusinessCardSample1/>
            )
        case 2:
            return (
                <BusinessCardSample2/>
            )
        case 3:
            return (
                <BusinessCardSample3/>
            )
        case 4:
            return (
                <BusinessCardSample4/>
            )
    }
});

export default BusinessCard;