import React, {useState} from 'react';
import styles from './DesignEditorPin.module.css'
import {Col, Row} from "react-bootstrap";
import BusinessCard from "../DesignEditorBusinessCard/BusinessCard/BusinessCard";
import BusinessCardParameters from "../DesignEditorBusinessCard/BusinessCard/BusinessCardParameters";
import ContactUsWithFile from "../../modals/contactUsWithFile/contactUsWithFile";
import Pin from "./Pin/Pin";
import PinParameters from "./Pin/PinParameters";

const DesignEditorPin = () => {
    const [isModalOpen, setIsModalOpen]= useState(false);

    return (
        <>
            <Row className={styles.editor}>
                <Col lg={8} className={styles.canvas}>
                    <Pin/>
                </Col>
                <Col lg={4} className={styles.parameters}>
                    <PinParameters/>
                    <div className={styles.contactUsBlock}>
                        <div className={styles.contactUs} onClick={() => setIsModalOpen(true)}>
                            Отправить нам
                        </div>
                    </div>
                </Col>
            </Row>
            <ContactUsWithFile show={isModalOpen} onHide={() => setIsModalOpen(false)} type={'pin'}/>
        </>
    );
};

export default DesignEditorPin;