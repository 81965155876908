import React, {useContext} from 'react';
import styles from './CreateDesignMainPage.module.css'
import {Col, Container, Row} from "react-bootstrap";
import DesignEditorElement from "../../components/DesignEditorElement/DesignEditorElement";
import businessCard from "../../images/businessCard.png"
import leaflet from "../../images/leaflet.png"
import pin from "../../images/pin.png"
import table from "../../images/table.png"
import ContactUs from "../../components/ContactUs/ContactUs";
import {Context} from "../../index";

const CreateDesignMainPage = () => {
    const {global} = useContext(Context);
    global.setIsAdminPage(false);

    return (
        <Container>
            <div className={styles.createDesignHeader}>
                Выберите, что вам нужно создать:
            </div>
            <Row>
                <Col md={6} className='mt-4'>
                    <DesignEditorElement name={'Визитку'} image={businessCard} route={'/business_card'}/>
                </Col>
                <Col md={6} className='mt-4'>
                    <DesignEditorElement name={'Значок'} image={pin} route={'/pin'}/>
                </Col>
                <Col md={6} className='mt-md-5 mt-4'>
                    <DesignEditorElement name={'Кабинетную табличку'} image={table} route={'/table'}/>
                </Col>
                <Col md={6} className='mt-md-5 mt-4'>
                    <DesignEditorElement name={'Листовку'} image={leaflet} route={'/leaflet'}/>
                </Col>
            </Row>
            <div className={styles.createDesignHeader}>
                Не нашли то, что искали?
            </div>
            <div className={styles.designersHelp}>
                Наши дизайнеры с радостью вам помогут!
            </div>
            <div className={styles.contactUs}>
                <ContactUs text='Напишите нам' color='blue'/>
            </div>
        </Container>
    );
};

export default CreateDesignMainPage;