import React, {useContext} from 'react';
import styles from './ErrorPage.module.css'
import {Context} from "../../index";

const ErrorPage = () => {
    const {global} = useContext(Context);
    global.setIsAdminPage(false);

    return (
        <div className={styles.heading}>
            <div className={styles.error}>404</div>
            <div className={styles.errorText}>Такой страницы не существует!</div>
        </div>
    );
};

export default ErrorPage;