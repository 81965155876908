import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import styles from "../Calculation.module.css";
import {Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import narrowPostcard from "../../../images/narrowSide.png";

const PostcardBlockNarrow = observer(() => {
    const {postcardCalculation} = useContext(Context);
    const tooltip =
        <Tooltip id="tooltip" className='ms-2'>
            Кол-во визиток должно быть кратно 3
        </Tooltip>

    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            <div>
                <img src={narrowPostcard} className={styles.headerImage} alt=''/>
            </div>
            <div className={styles.group}>
                <div className={styles.label}>
                    Бумага:
                </div>
                <div>
                    <Form.Select aria-label="Default select example" className={styles.selectInput}
                                 onChange={(e) => postcardCalculation.setCalculatingPaperTypeNarrow(e.target.value)}
                                 value={postcardCalculation.calculatingPaperTypeNarrow}>
                        <option value={'narrowPaper300g'} key={'narrowPaper300g'}>
                            Бумага 300 грамм
                        </option>
                        <option value={'narrowDesignPaper1'} key={'narrowDesignPaper1'}>
                            Бумага дизайнерская 1-го типа
                        </option>
                        <option value={'narrowDesignPaper2'} key={'narrowDesignPaper2'}>
                            Бумага дизайнерская 2-го типа
                        </option>
                        <option value={'narrowDesignPaper3'} key={'narrowDesignPaper3'}>
                            Бумага дизайнерская 3-го типа
                        </option>
                    </Form.Select>
                </div>
            </div>
            <div className={styles.group}>
                <div className={styles.label}>
                    Количество:
                </div>
                <div>
                    <OverlayTrigger placement="right" overlay={tooltip}>
                        <Form.Control type="number" name="count" min={0} step={3}
                                      value={postcardCalculation.calculatingCountNarrow}
                                      className={styles.selectInput}
                                      onChange={(e) => postcardCalculation.setRealCalculatingCountNarrow(e.target.value)}
                                      onBlur={(e) => postcardCalculation.setCalculatingCountNarrow(e.target.value)}
                        />
                    </OverlayTrigger>
                </div>
            </div>
            <div className={styles.totalPriceBlock}>
                <div>
                    Итог: <span
                    className={styles.totalPrice}>{postcardCalculation.totalPriceNarrow}</span> руб.
                </div>
            </div>
            <div className={styles.info}>
                <span className={styles.redStar}>*</span> Фигурная вырубка, фольгирование, тиснение, оформление
                дополнительными украшениями расчитываются индивидуально в зависимости от макета
            </div>
            <div className={styles.contactUsBlock}>
                <div className={styles.contactUs}>
                    Заказать
                </div>
            </div>
        </Form>
    );
});

export default PostcardBlockNarrow;