import {makeAutoObservable} from "mobx";
import {getPrice} from "../../http/pricesAPI";

export default class BusinessCardCalculationStore {
    constructor() {
        this._prices = {};
        this._error = false;
        this._calculatingSides = 'printingOneSide';
        this._calculatingPaperType = 'paper300g';
        this._calculatingCount = 0;
        this._totalPrice = 0;
        this._previousCount = -1;
        makeAutoObservable(this);
    }

    setPrices(prices) {
        this._prices = prices;
    }

    setError(error) {
        this._error = error;
    }

    setCalculatingSides(sides) {
        this._calculatingSides = sides;
        this.calculatePrice();
    }

    setCalculatingPaperType(type) {
        this._calculatingPaperType = type;
        this.calculatePrice();
    }

    setCalculatingCount(count) {
        this.setPreviousCount(this.calculatingCount);
        if (count <= 0) {
            this._calculatingCount = 0;
        } else if (count < this.previousCount) {
            this._calculatingCount = (24 * Math.floor(count / 24));
        } else {
            this._calculatingCount = (24 * Math.ceil(count / 24));
        }
        this.calculatePrice();
    }

    setRealCalculatingCount(count) {
        this._calculatingCount = count;
    }

    setTotalPrice(price) {
        this._totalPrice = price;
    }

    setPreviousCount(count) {
        this._previousCount = count;
    }

    get prices() {
        return this._prices;
    }

    get error() {
        return this._error;
    }

    get calculatingSides() {
        return this._calculatingSides;
    }

    get calculatingPaperType() {
        return this._calculatingPaperType;
    }

    get calculatingCount() {
        return this._calculatingCount;
    }

    get totalPrice() {
        return this._totalPrice;
    }

    get previousCount() {
        return this._previousCount;
    }

    async loadPrices() {
        try {
            const prices = await getPrice('businessCard');
            this.setPrices(prices);
            this.setError(false);
            this.calculatePrice();
        } catch (e) {
            this.setError(true);
        }
    }

    calculatePrice() {
        let price = this.calculatingCount * (this.prices[this.calculatingPaperType] + this.prices[this.calculatingSides]);
        this.setTotalPrice(price);
    }
}