import {makeAutoObservable} from "mobx";
import {getPrice} from "../../http/pricesAPI";

export default class DiscountCardCalculationStore {
    constructor() {
        this._prices = {};
        this._error = false;
        this._calculatingLaminationType = 'matteLamination';
        this._calculatingCount = 0;
        this._previousCount = -1;
        this._totalPrice = 0;
        makeAutoObservable(this);
    }

    setPrices(prices) {
        this._prices = prices;
    }

    setError(error) {
        this._error = error;
    }

    setCalculatingLaminationType(type) {
        this._calculatingLaminationType = type;
        this.calculatePrice();
    }

    setCalculatingCount(count) {
        this.setPreviousCount(this.calculatingCount);
        if (count <= 0) {
            this._calculatingCount = 0;
        } else if (count < this.previousCount) {
            this._calculatingCount = (21 * Math.floor(count / 21));
        } else {
            this._calculatingCount = (21 * Math.ceil(count / 21));
        }
        this.calculatePrice();
    }

    setRealCalculatingCount(count) {
        this._calculatingCount = count;
    }

    setTotalPrice(price) {
        this._totalPrice = price;
    }

    setPreviousCount(count) {
        this._previousCount = count;
    }

    get prices() {
        return this._prices;
    }

    get error() {
        return this._error;
    }

    get calculatingLaminationType() {
        return this._calculatingLaminationType;
    }

    get calculatingCount() {
        return this._calculatingCount;
    }

    get totalPrice() {
        return this._totalPrice;
    }

    get previousCount() {
        return this._previousCount;
    }

    async loadPrices() {
        try {
            const prices = await getPrice('discountCard');
            this.setPrices(prices);
            this.setError(false);
            this.calculatePrice();
        } catch (e) {
            this.setError(true);
        }
    }

    calculatePrice() {
        let price = this.calculatingCount * (this.prices[this.calculatingLaminationType] + this.prices['printing']);
        this.setTotalPrice(price);
    }
}