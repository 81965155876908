import React, {useContext, useEffect, useState} from 'react';
import styles from './EditNews.module.css'
import {Button, Card, Container, Spinner,} from "react-bootstrap";
import {Context} from "../../../index";
import swal from 'sweetalert';
import {observer} from 'mobx-react-lite';
import ModalCreateAndUpdateNews from "../../../components/modals/modalNewsEditor/modalCreateAndUpdateNews";
import PostBody from "../../../components/postBody/postBody";
import ErrorPage from "../../ErrorPage/ErrorPage";
import PaginationBlock from "../../../components/Pagination/PaginationBlock";
import {EDIT_NEWS_ROUTE} from "../../../utils/const";

const EditNews = observer(() => {
    const {posts} = useContext(Context);
    const {global} = useContext(Context);
    global.setIsAdminPage(true);
    const [show, setShow] = useState(false);
    const [modalType, setModalType] = useState('create');
    const [isPostsLoaded, setIsPostsLoaded] = useState(false);

    const [pageNumber, setPageNumber] = useState(window.location.search.split('=')[1] ?? '');

    useEffect(() => {
        setIsPostsLoaded(false);
        posts.loadPosts(pageNumber).then(() => setIsPostsLoaded(true));
    }, [pageNumber])

    if (posts.error) {
        return <ErrorPage/>
    }

    const showAlert = () => {
        swal({
            title: "Вы уверены, что хотите удалить новость?",
            icon: "warning",
            buttons: {
                cancel: "Нет, не удалять",
                catch: {
                    text: "Да, удалить!",
                    value: "Да, удалить!",
                },
            },
        })
            .then((value) => {
                switch (value) {

                    case "Да, удалить!":
                        swal("Удаление поста", "Пост успешно удален!", "success");
                        posts.deletePost();
                        break;
                }
                <br/>
            });
    }

    return (
        <>
            {
                isPostsLoaded ?
                    <>
                        <Container className={styles.container}>
                            <Button className={styles.createPost} type='submit' onClick={
                                () => {
                                    setShow(true);
                                    setModalType('create')
                                }
                            }>
                                Создать новость
                            </Button>
                            {isPostsLoaded ? posts.posts.map((el, index) => (
                                <Card className={styles.card}>
                                    <PostBody data={el} index={index} key={index}/>
                                    <div className={styles.buttons}>
                                        <Button className={styles.deletePost} type='submit' onClick={
                                            () => {
                                                posts.setEditingPostId(el.id);
                                                showAlert();
                                            }
                                        }>
                                            Удалить новость
                                        </Button>
                                        <Button className={styles.editPost} type='submit'>
                                            Редактировать новость
                                        </Button>
                                    </div>
                                </Card>
                            )) : ''}
                            <ModalCreateAndUpdateNews show={show} type={modalType} onHide={() => setShow(false)}/>
                        </Container>
                        <div className={styles.pagination}>
                            <PaginationBlock count={posts.pagination.count} current={posts.pagination.current}
                                             route={EDIT_NEWS_ROUTE} setPageNumber={setPageNumber}/>
                        </div>
                    </>
                    :
                    <Spinner className="spinner" animation={"grow"}/>
            }
        </>
    );
});

export default EditNews;