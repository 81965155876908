import React, {useContext, useEffect, useState} from 'react';
import styles from '../Calculation.module.css';
import {Container, Form, OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";

const BusinessCardCalculation = observer(() => {
    const {businessCardCalculation} = useContext(Context);
    const [isPricesLoaded, setIsPricesLoaded] = useState(false);

    useEffect(() => {
        businessCardCalculation.loadPrices().then(() => setIsPricesLoaded(true));
    }, []);

    const tooltip =
        <Tooltip id="tooltip" className='ms-2'>
            Кол-во визиток должно быть кратно 24
        </Tooltip>

    return (
        <Container>
            {
                isPricesLoaded ?

                    <Form className={styles.calculatorCard} onSubmit={(e) => e.preventDefault()}>
                        <div className={styles.calculateHeader}>
                            Расчёт стоимости визиток
                        </div>
                        <div className={styles.group}>
                            <div className={styles.label}>
                                Печать:
                            </div>
                            <div>
                            <span className={styles.choiceBlock}>
                                <input type="radio" name="pickerSide" value='printingOneSide' id='1'
                                       checked={businessCardCalculation.calculatingSides === 'printingOneSide'}
                                       onChange={(e) => businessCardCalculation.setCalculatingSides(e.target.value)}/>
                                <label htmlFor='1' className={styles.choice}>Односторонняя</label>
                            </span>
                                <span className={styles.choiceBlock}>
                                    <input type="radio" name="pickerSide" value='printingTwoSides' id='2'
                                           checked={businessCardCalculation.calculatingSides === 'printingTwoSides'}
                                           onChange={(e) => businessCardCalculation.setCalculatingSides(e.target.value)}/>
                                    <label htmlFor='2' className={styles.choice}>Двусторонняя</label>
                            </span>
                            </div>
                        </div>
                        <div className={styles.group}>
                            <div className={styles.label}>
                                Бумага:
                            </div>
                            <div>
                                <Form.Select aria-label="Default select example" className={styles.selectInput}
                                             onChange={(e) => businessCardCalculation.setCalculatingPaperType(e.target.value)}
                                             value={businessCardCalculation.calculatingPaperType}>
                                    <option value={'paper300g'} key={'paper300g'}>
                                        Бумага 300 грамм
                                    </option>
                                    <option value={'designPaper1'} key={'designPaper1'}>
                                        Бумага дизайнерская 1-го типа
                                    </option>
                                    <option value={'designPaper2'} key={'designPaper2'}>
                                        Бумага дизайнерская 2-го типа
                                    </option>
                                    <option value={'designPaper3'} key={'designPaper3'}>
                                        Бумага дизайнерская 3-го типа
                                    </option>
                                </Form.Select>
                            </div>
                        </div>
                        <div className={styles.group}>
                            <div className={styles.label}>
                                Количество:
                            </div>
                            <div>
                                <OverlayTrigger placement="right" overlay={tooltip}>
                                    <Form.Control type="number" name="count" step={24} min={0}
                                                  value={businessCardCalculation.calculatingCount}
                                                  className={styles.selectInput}
                                                  onChange={(e) => businessCardCalculation.setRealCalculatingCount(e.target.value)}
                                                  onBlur={(e) => businessCardCalculation.setCalculatingCount(e.target.value)}
                                    />
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className={styles.totalPriceBlock}>
                            <div>
                                Итог: <span
                                className={styles.totalPrice}>{businessCardCalculation.totalPrice}</span> руб.
                            </div>
                        </div>
                        <div className={styles.info}>
                            <span className={styles.redStar}>*</span> Тиснение, фольгирование, конгрев расчитываются
                            индивидуально
                        </div>
                        <div className={styles.contactUsBlock}>
                            <div className={styles.contactUs}>
                                Заказать
                            </div>
                        </div>
                    </Form>

                    :
                    <Spinner className="spinner" animation={"grow"}/>
            }
        </Container>
    );
});

export default BusinessCardCalculation;