import {makeAutoObservable} from "mobx";
import {storeBanner, editBanner, getAllBanners, dropBanner} from "../http/bannersAPI";

export default class BannersStore {
    constructor() {
        this._banners = [];
        this._error = false;
        this._errorMessage = '';
        this._editingBannerId = '';
        makeAutoObservable(this);
    }

    async loadBanners() {
        try {
            const banners = await getAllBanners();
            this.setBanners(banners);
            this.setError(false);
        } catch (e) {
            this.setError(true);
            this.setErrorMessage('Ошибка загрузки');
        }
    }
    async createBanner() {
        try {
            await storeBanner();
            this.setError(false);
            await this.loadBanners();
        } catch (e) {
            this.setError(true);
        }
    }
    async updateBanner() {
        try {
            await editBanner(this.editingBannerId);
            this.setError(false);
            await this.loadBanners();
        } catch (e) {
            this.setError(true);
        }
    }
    async deleteBanner() {
        try {
            await dropBanner(this.editingBannerId);
            this.setError(false);
            await this.loadBanners();
        } catch (e) {
            this.setError(true);
        }
    }

    setBanners(banners) {
        this._banners = banners;
    }
    setError(error) {
        this._error = error;
    }
    setErrorMessage(errorMessage) {
        this._errorMessage = errorMessage;
    }
    setEditingBannerId(id) {
        this._editingBannerId = id;
    }

    get banners() {
        return this._banners;
    }
    get error() {
        return this._error;
    }
    get errorMessage() {
        return this._errorMessage;
    }
    get editingBannerId() {
        return this._editingBannerId;
    }
}