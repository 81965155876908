export const MAIN_ROUTE = '/';
export const NEWS_ROUTE = '/news';
export const LAYOUTS_AND_DESIGN_ROUTE = '/layouts_and_design';
export const CONTACTS_ROUTE = '/contacts';
export const CALCULATORS_LIST_ROUTE = '/calculators_list';
export const CALCULATE_ROUTE = '/calculate';
export const CREATE_DESIGN_ROUTE = '/create_design';
export const ADMIN_ROUTE = '/admin';
export const LOGIN_ROUTE = ADMIN_ROUTE + '/login'
export const EDIT_BANNERS_ROUTE = ADMIN_ROUTE + '/edit_banners'
export const EDIT_NEWS_ROUTE = ADMIN_ROUTE + '/edit_news'
export const EDIT_PRICES_ROUTE = ADMIN_ROUTE + '/edit_prices'

export const BASE_URL = 'https://4-zveta.ru/api/';