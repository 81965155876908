import styles from './PricingName.module.css'

const PricingName = ({title}) => {

    return (
        <div className={styles.mainTitle}>
            <div className={styles.title}>{title}</div>
            <div className={styles.space} />
        </div>
    );
};

export default PricingName;