import React, {useEffect, useRef, useState} from 'react';
import styles from'./EditPricesComponents.module.css'
import {Col, Form, Row} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import {changePrice} from "../../../../../http/pricesAPI";
import swal from "sweetalert";

const EditPricesField = observer(({text, type, price, itemType}) => {
    const [inputValue, setInputValue] = useState(price);
    return (
        <Form className={styles.form}>
            <Row>
                <Col className={styles.item} sm={12} md={4}>
                    {text}
                </Col>
                <Col className='mt-sm-3 mt-md-0'>
                    <Form.Control className={styles.inputBlock} value={inputValue} onChange={(e) => setInputValue(e.target.value)}/>
                </Col>
                <Col sm={12} md={2} className='mt-sm-3 mt-md-0'>
                    <button className={styles.button} onClick={(e) => {
                        e.preventDefault();
                        changePrice(itemType, type, inputValue).then(
                            swal("Успешно!", "Цена изменена", "success", {
                                buttons: false,
                            })
                        );
                    }}>
                        Изменить
                    </button>
                </Col>
            </Row>
        </Form>
    );
});

export default EditPricesField;