import React, {useContext} from 'react';
import styles from "../BusinessCard.module.css";
import stylesSample1 from "./BusinessCardSample1.module.css";
import {observer} from "mobx-react-lite";
import {Context} from "../../../../../index";

const BusinessCardSample1 = observer(() => {
    const {businessCard} = useContext(Context);

    return (
        <div className={styles.businessCard}>
            <div id='businessCardContent'>
                <div className={`${styles.padding} ${stylesSample1.background}`}>
                    <div className={styles.content}>
                        <div className={stylesSample1.header}>
                            {businessCard.header}
                        </div>
                        <div className={stylesSample1.mainInf}>
                            <div className={stylesSample1.name}>
                                {businessCard.name}
                            </div>
                            <div className={stylesSample1.jobTitle}>
                                {businessCard.jobTitle}
                            </div>
                        </div>
                        <div className={stylesSample1.informationBlock}>
                            <div>
                                {businessCard.phone}
                            </div>
                            <div>
                                {businessCard.email}
                            </div>
                            <div>
                                {businessCard.address}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default BusinessCardSample1;