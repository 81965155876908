import React from 'react';
import styles from './readMoreButton.module.css';
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";
import {NEWS_ROUTE} from "../../utils/const";

const ReadMoreButton = () => {
    const navigate = useNavigate();
    return (
        <Button className={styles.readMore} onClick={() => navigate(NEWS_ROUTE)}>
            Читать еще
        </Button>
    );
};

export default ReadMoreButton;