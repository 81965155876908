import React from 'react';
import styles from './EditPricesListElement.module.css'
import {useNavigate} from "react-router-dom";
import {EDIT_PRICES_ROUTE} from "../../utils/const";

const EditPricesListElement = ({text, type}) => {
    const navigate = useNavigate();
    return (
        <div className={styles.block} onClick={() => navigate(EDIT_PRICES_ROUTE + '/' + type)}>
            <div className={styles.header}>
                {text}
            </div>
        </div>
    );
};

export default EditPricesListElement;