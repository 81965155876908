import React, {useContext} from 'react';
import styles from './BusinessCardParameterBlock.module.css'
import {observer} from "mobx-react-lite";
import {Context} from "../../../../../index";

const BusinessCardParameterBlock = observer(({number, image}) => {
    const {businessCard} = useContext(Context);
    return (
        <div className={styles.sampleBlock} onClick={() => businessCard.setCurrentSample(number)}>
            <img
                src={image} alt={number}
                className={`${styles.sampleBlockImage} ${(businessCard.currentSample === number) ? styles.sampleBlockSelected : ''}`}
            />
        </div>
    );
});

export default BusinessCardParameterBlock;