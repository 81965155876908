import React, { useContext } from 'react';
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { Context } from "../../../index";
import Form from 'react-bootstrap/Form';
import {observer} from "mobx-react-lite";

const ModalCreateAndUpdateNews = observer(({ show, onHide, type }) => {
    const { posts } = useContext(Context);

    return (
        <Modal show={show} onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title>{type === 'create' ? 'Добавление новости' : 'Изменение новости'}</Modal.Title>
            </Modal.Header>
            <form onSubmit={(event) => {
                event.preventDefault();
                switch (type) {
                    case 'create':
                        posts.createPost().then(() => onHide());
                        break;
                    case 'update':
                        posts.updateBanner().then(() => onHide());
                }
                onHide();
            }}>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Заголовок</Form.Label>
                        <Form.Control type="name" placeholder="Заголовок" value={posts.currentHeader} onChange={(e) => posts.setCurrentHeader(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <input type="file" className="form-control mb-2" id="image1" name='image1' />
                        <input type="file" className="form-control mb-2" id="image2" name='image2' />
                        <input type="file" className="form-control" id="image3" name='image3' />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Текст новости</Form.Label>
                        <Form.Control placeholder="Текст новости" value={posts.currentText} onChange={(e) => posts.setCurrentText(e.target.value)} as="textarea" rows={3} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        Закрыть
                    </Button>
                    <Button variant="primary" type='submit'>
                        {type === 'create' ? 'Создать' : 'Изменить'}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
});

export default ModalCreateAndUpdateNews;