import React, {useContext, useEffect} from 'react';
import BusinessCardCalculation from "../../components/Calculation/BusinessCardCalculation/BusinessCardCalculation";
import DiscountCardCalculation from "../../components/Calculation/DiscountCardCalculation/DiscountCardCalculation";
import {Context} from "../../index";
import LeafletCalculation from "../../components/Calculation/LeafletCalculation/LeafletCalculation";
import PostcardCalculation from "../../components/Calculation/PostcardCalculation/PostcardCalculation";

const CalculateItem = () => {
    const {global} = useContext(Context);
    global.setIsAdminPage(false);
    const item = window.location.pathname.split('/')[2];

    switch (item) {
        case 'businessCard':
            return <BusinessCardCalculation/>
        case 'discountCard':
            return <DiscountCardCalculation/>
        case 'leaflet':
            return <LeafletCalculation/>
        case 'postcard':
            return <PostcardCalculation/>
    }
};

export default CalculateItem;