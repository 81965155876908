import React, {useContext} from 'react';
import styles from '../Calculation.module.css'
import {Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";

const LeafletBlockColour = observer(() => {
    const {leafletCalculation} = useContext(Context);

    const tooltip =
        <Tooltip id="tooltip" className='ms-2'>
            Минимальное кол-во листовок:
            <div>
                A3: 1
            </div>
            <div>
                A4: 1
            </div>
            <div>
                A5: 2
            </div>
            <div>
                A6: 4
            </div>
        </Tooltip>

    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            <div className={styles.group}>
                <div className={styles.label}>
                    Размер:
                </div>
                <div>
                    <Form.Select aria-label="Default select example" className={styles.selectInput}
                                 onChange={(e) => leafletCalculation.setCalculatingSizeColour(e.target.value)}
                                 value={leafletCalculation.calculatingSizeColour}>
                        <option value={'A3'} key={'A3'}>
                            Формат A3
                        </option>
                        <option value={'A4'} key={'A4'}>
                            Формат A4
                        </option>
                        <option value={'A5'} key={'A5'}>
                            Формат A5
                        </option>
                        <option value={'A6'} key={'A6'}>
                            Формат A6
                        </option>
                    </Form.Select>
                </div>
            </div>
            <div className={styles.group}>
                <div className={styles.label}>
                    Печать:
                </div>
                <div>
                    <span className={styles.choiceBlock}>
                        <input type="radio" name="pickerSide" value='printingOneSideA3Color' id='1'
                               checked={leafletCalculation.calculatingSidesColour === 'printingOneSideA3Color'}
                               onChange={(e) => leafletCalculation.setCalculatingSidesColour(e.target.value)}/>
                        <label htmlFor='1' className={styles.choice}>Односторонняя</label>
                    </span>
                    <span className={styles.choiceBlock}>
                        <input type="radio" name="pickerSide" value='printingTwoSidesA3Color' id='2'
                               checked={leafletCalculation.calculatingSidesColour === 'printingTwoSidesA3Color'}
                               onChange={(e) => leafletCalculation.setCalculatingSidesColour(e.target.value)}/>
                        <label htmlFor='2' className={styles.choice}>Двусторонняя</label>
                    </span>
                </div>
            </div>
            <div className={styles.group}>
                <div className={styles.label}>
                    Бумага:
                </div>
                <div>
                    <Form.Select aria-label="Default select example" className={styles.selectInput}
                                 onChange={(e) => leafletCalculation.setCalculatingPaperTypeColour(e.target.value)}
                                 value={leafletCalculation.calculatingPaperTypeColour}>
                        <option value={'paper100gA3'} key={'paper100gA3'}>
                            Бумага 100 грамм
                        </option>
                        <option value={'paper115gA3'} key={'paper115gA3'}>
                            Бумага 115 грамм
                        </option>
                        <option value={'paper130gA3'} key={'paper130gA3'}>
                            Бумага 130 грамм
                        </option>
                        <option value={'paper160gA3'} key={'paper160gA3'}>
                            Бумага 160 грамм
                        </option>
                    </Form.Select>
                </div>
            </div>
            <div className={styles.group}>
                <div className={styles.label}>
                    Количество:
                </div>
                <div>
                    <OverlayTrigger placement="right" overlay={tooltip}>
                        <Form.Control type="number" name="count" min={0}
                                      value={leafletCalculation.calculatingCountColour}
                                      className={styles.selectInput}
                                      onChange={(e) => leafletCalculation.setRealCalculatingCountColour(e.target.value)}
                                      onBlur={(e) => leafletCalculation.setCalculatingCountColour(e.target.value)}
                        />
                    </OverlayTrigger>
                </div>
            </div>
            <div className={styles.totalPriceBlock}>
                <div>
                    Итог: <span
                    className={styles.totalPrice}>{leafletCalculation.totalPriceColour}</span> руб.
                </div>
            </div>
            <div className={styles.contactUsBlock}>
                <div className={styles.contactUs}>
                    Заказать
                </div>
            </div>
        </Form>
    );
});

export default LeafletBlockColour;