import {makeAutoObservable} from "mobx";

export default class BusinessCardStore {
    constructor() {
        this._currentSample = 1;
        this._name = 'Фамилия Имя Отчество';
        this._header = 'Название вашей компании';
        this._jobTitle = 'Ваша должность';
        this._phone = '+7-999-999-9999';
        this._email = 'example_email@yandex.ru';
        this._address = 'г. Москва, ул. Строителей, д. 234, стр. 23, офис 3'
        makeAutoObservable(this);
    }

    setCurrentSample(sampleNumber) {
        this._currentSample = sampleNumber;
    }
    setName(name) {
        this._name = name;
    }
    setHeader(header) {
        this._header = header;
    }
    setJobTitle(jobTitle) {
        this._jobTitle = jobTitle;
    }
    setPhone(phone) {
        this._phone = phone;
    }
    setEmail(email) {
        this._email = email;
    }
    setAddress(address) {
        this._address = address;
    }

    get currentSample() {
        return this._currentSample;
    }
    get name() {
        return this._name;
    }
    get header() {
        return this._header;
    }
    get jobTitle() {
        return this._jobTitle;
    }
    get phone() {
        return this._phone;
    }
    get email() {
        return this._email;
    }
    get address() {
        return this._address;
    }
}