import {makeAutoObservable} from "mobx";
import * as htmlToImage from "html-to-image";
import {sendForm} from "../http/contactAPI";
import convertBase64toPng from "../utils/convertBase64toPng";
import contacts from "../pages/Contacts/Contacts";
import axios from "axios";
import {BASE_URL} from "../utils/const";
import {storeBanner} from "../http/bannersAPI";

export default class ContactStore {
    constructor() {
        this._name = '';
        this._email = '';
        this._text = '';
        this._file = '';
        this._isFormSent = false;
        this._type = '';
        this._status = '';
        makeAutoObservable(this);
    }

    setName(name) {
        this._name = name;
    }

    setText(text) {
        this._text = text;
    }

    setEmail(email) {
        this._email = email;
    }

    setFile(file) {
        this._file = file;
    }

    setIsFormSent(value) {
        this._isFormSent = value;
    }

    get name() {
        return this._name;
    }

    get text() {
        return this._text;
    }

    get email() {
        return this._email;
    }

    get file() {
        return this._file;
    }

    get isFormSent() {
        return this._isFormSent;
    }

    clearAllFields() {
        this.setName('');
        this.setText('');
        this.setEmail('');
        this.setFile('');
        this.setIsFormSent(false);
        this.setTypeText(this._type);

        const file = document.getElementById('customFile');

        if (file) {
            file.value = null;
        }
    }

    setTypeText(type) {
        this._type = type;
        switch (type) {
            case 'businessCard':
                this.setText('Здравствуйте! Хотел бы заказать визитки по созданному мной макету.');
                break;
            case 'leaflet':
                this.setText('Здравствуйте! Хотел бы заказать листовки по созданному мной макету.');
                break;
            case 'pin':
                this.setText('Здравствуйте! Хотел бы заказать значки по созданному мной макету.');
                break;
            case 'table':
                this.setText('Здравствуйте! Хотел бы заказать кабинетную табличку по созданному мной макету.');
        }
    }

    async confirmSending() {
        const node = document.getElementById(this._type + 'Content');
        const name = this._name;
        const email = this._email;
        const text = this._text;
        htmlToImage.toPng(node)
            .then(function (dataUrl) {
                const file = convertBase64toPng(dataUrl, 'user_file');
                const data = sendForm(name, email, text, file);
            });
    }

    async sendForm() {
        const name = this._name;
        const email = this._email;
        const text = this._text;
        const fileEl = document.getElementById('customFile') ?? null;

        let file = null;
        
        if (fileEl) {
            file = fileEl.files[0];
        }

        try {
            await sendForm(name, email, text, file);
        } catch (e) {
        }
    }
}