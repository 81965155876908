import styles from './PricingNameWithPrice.module.css'

const PricingNameWithPrice = ({ title, price }) => {

    return (
        <div className={styles.mainTitleWithPrice}>
            <div className={styles.title}>{title}</div>
            <div className={styles.contentPrice}>{price}</div>
        </div>
    );
};

export default PricingNameWithPrice;