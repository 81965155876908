import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../../../index";
import styles from "./PinParameters.module.css";
import {Form} from "react-bootstrap";

const PinParameters = observer(() => {
    const {pin} = useContext(Context);

    return (
        <div className={styles.parametersBody}>
            <div className={styles.parameterName}>
                Введите данные
            </div>
            <Form>
                <Form.Group className="mb-2">
                    <Form.Label>Название компании</Form.Label>
                    <Form.Control onChange={(e) => pin.setHeader(e.target.value)} value={pin.header}/>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Телефон</Form.Label>
                    <Form.Control onChange={(e) => pin.setPhone(e.target.value)} value={pin.phone}/>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Фон (в виде URL)</Form.Label>
                    <Form.Control onChange={(e) => pin.setImage(e.target.value)} value={pin.image}/>
                </Form.Group>
            </Form>
        </div>
    );
});

export default PinParameters;