import React, { useContext } from 'react';
import styles from './LayoutsAndDesign.module.css'
import { Context } from "../../index";
import PricingName from '../../components/Price/PricingName/PricingName.js';
import PricingNameWithPrice from '../../components/Price/PricingNameWithPrice/PricingNameWithPrice';
import PricingSub from '../../components/Price/PricingSub/PrisingSub';
import {Container} from "react-bootstrap";

const LayoutsAndDesign = () => {
    const { global } = useContext(Context);
    global.setIsAdminPage(false);

    return (
        <Container className={styles.layoutsAndDesign}>
            <h1>Расценки на услуги дизайна</h1>
            <h4>Дизайн информационных и рекламных материалов</h4>
            <div className={styles.container}>
                <PricingName title={`Визитная карточка (в зависимости от сложности)`} />
                <PricingSub title={`индивидуального решения`} price={`от 500 до 1 000 руб.`} />
                <PricingSub title={`с готовым решением (по шаблону)`} price={`300 руб.`} />
                <PricingSub title={`смена информации на готовом макете визитки (одного вида)`} price={`100 руб.`} />
                <PricingNameWithPrice title={`Грамоты, сертификаты (в зависимости от сложности)`} price={`от 1 000 до 2 000 руб.`} />
                <PricingNameWithPrice title={`Открытки, пригласительные`} price={`от 2 500 до 5 000 руб`} />
                <PricingNameWithPrice title={`Листовки А4, А5 формат (одна сторона)`} price={`от 1 000 до 3 000 руб.`} />
                <PricingNameWithPrice title={`Плакат, афиша, постер (в зависимости от сложности)`} price={`от 1 000 до 3 000 руб.`} />
                <PricingNameWithPrice title={`Буклет (трифолд, 6 полос)`} price={`от 1 000 до 3 000 руб.`} />
                <PricingName title={`Вывески, таблички, баннера`} />
                <PricingSub title={`простой текстовый макет`} price={`от 500 до 1 000 руб.`} />
                <PricingSub title={`совмещенная техника исполнения, сложное графическое решение`} price={`от 1 000 до 5 000 руб..`} />
                <PricingNameWithPrice title={`Макет стенда (в зависимости от сложности)`} price={`от 1 000 до 3 000 руб.`} />
                <PricingNameWithPrice title={`Оформление подарочной бумаги`} price={`от 800 до 1 200 руб`} />
            </div>
            <h4>Дизайн идентификационных материалов</h4>
            <div className={styles.container}>
                <PricingNameWithPrice title={`Разработка логотипа`} price={`от 12 000 руб.`} />
                <PricingNameWithPrice title={`Обрисовка логотипа - перевод в векторный формат (в зависимости от сложности)`} price={`от 3 000 до 6 000 руб`} />
            </div>
            <h4>Работа с текстом</h4>
            <div className={styles.container}>
                <PricingNameWithPrice title={`Набор текста (формат А4, одна сторона, 14 кегль)`} price={`50 руб.`} />
                <PricingNameWithPrice title={`Верстка текста (1 полоса)`} price={`200 руб`} />
            </div>
            <h4>Внесение изменений</h4>
            <div className={styles.container}>
                <PricingName title={`Технологическая подготовка макета к офсетной печати`} />
                <PricingSub title={`без подборки цвета`} price={`300 руб.`} />
                <PricingSub title={`с подборкой цвета`} price={`600 руб.`} />
                <PricingNameWithPrice title={`Макет для ризографии`} price={`300 руб.`} />
                <PricingName title={`Работа с фотографиями`} />
                <PricingSub title={`высветлить фотографию`} price={`200 руб.`} />
                <PricingSub title={`обработка фотографии`} price={`400 руб..`} />
            </div>
            <h1>Требования к макетам</h1>
            <div className={styles.downloadFile}>
            <button className={styles.btnDownload}><a href={require('./../../file/Trebovania.pdf')}
                download="Требования к макетам"
                target="_blank">Скачать требования</a></button>
            </div>
        </Container>
    );
};

export default LayoutsAndDesign;