function convertDate(date) {
    let year = date.slice(0, 4)
    let month = date.slice(5, 7)
    let day = date.slice(8, 10)
    switch (month) {
        case '01':
            month = 'янв.';
            break;
        case '02':
            month = 'фев.';
            break;
        case '03':
            month = 'мар.';
            break;
        case '04':
            month = 'апр.';
            break;
        case '05':
            month = 'мая';
            break;
        case '06':
            month = 'июн.';
            break;
        case '07':
            month = 'июл.';
            break;
        case '08':
            month = 'авг.';
            break;
        case '09':
            month = 'сен.';
            break;
        case '10':
            month = 'окт.';
            break;
        case '11':
            month = 'ноя.';
            break;
        case '12':
            month = 'дек.';
            break;
    }

    date = `${day} ${month} ${year}`
    return date;
}

export default convertDate;