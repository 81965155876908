import { makeAutoObservable } from "mobx";
import {getAllPosts, dropPost, getLastPost, storePost} from "../http/postsAPI";

export default class PostsStore {
    constructor() {
        this._posts = [];
        this._pagination = {};
        this._error = false;
        this._errorMessage = '';
        this._editingPostId = '';
        this._lastPost = {};
        this._currentHeader = '';
        this._currentText = '';
        makeAutoObservable(this);
    }

    setCurrentHeader(header) {
        this._currentHeader = header;
    }
    setCurrentText(text) {
        this._currentText = text;
    }
    setPosts(posts) {
        this._posts = posts;
    }
    setError(error) {
        this._error = error;
    }
    setErrorMessage(errorMessage) {
        this._errorMessage = errorMessage;
    }
    setEditingPostId(id) {
        this._editingPostId = id;
    }
    setLastPost(post) {
        this._lastPost = post;
    }
    setPagination(pagination) {
        this._pagination = pagination;
    }

    get posts() {
        return this._posts;
    }
    get error() {
        return this._error;
    }
    get errorMessage() {
        return this._errorMessage;
    }
    get editingPostId() {
        return this._editingPostId;
    }
    get lastPost() {
        return this._lastPost
    }
    get pagination() {
        return this._pagination;
    }
    get currentHeader() {
        return this._currentHeader;
    }
    get currentText() {
        return this._currentText;
    }

    async loadPosts(number) {
        try {
            const posts = await getAllPosts(number);
            this.setPosts(posts.posts);
            this.setPagination(posts.pagination);
            this.setError(false);
        } catch (e) {
            this.setError(true);
            this.setErrorMessage('Ошибка загрузки');
        }
    }

    async deletePost() {
        try {
            await dropPost(this.editingPostId);
            this.setError(false);
            await this.loadPosts();
        } catch (e) {
            this.setError(true);
        }
    }
    async createPost() {
        try {
            await storePost(this.currentHeader, this.currentText);
            this.setError(false);
            await this.loadPosts();
            this.setCurrentText('');
            this.setCurrentHeader('');
        } catch (e) {
            this.setError(true);
        }
    }
    async loadLastPost() {
        try {
            const post = await getLastPost();
            this.setLastPost(post);
            this.setError(false);
        } catch (e) {
            this.setError(true);
            this.setErrorMessage('Ошибка загрузки');
        }
    }
}
