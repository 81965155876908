import MainPage from "./pages/MainPage/MainPage";
import News from "./pages/News/News";
import LayoutsAndDesign from "./pages/LayoutsAndDesign/LayoutsAndDesign";
import Contacts from "./pages/Contacts/Contacts";
import CalculatorsList from "./pages/CalculatorsList/CalculatorsList";
import CreateDesignMainPage from "./pages/CreateDesignMainPage/CreateDesignMainPage";
import Login from "./pages/AdminPages/Login/Login";
import EditBanners from "./pages/AdminPages/EditBanners/EditBanners";
import EditNews from "./pages/AdminPages/EditNews/EditNews";
import EditPrices from "./pages/AdminPages/EditPrices/EditPrices";
import CreateDesign from "./pages/CreateDesign/CreateDesign";

import {
    ADMIN_ROUTE,
    CALCULATE_ROUTE,
    CALCULATORS_LIST_ROUTE,
    CONTACTS_ROUTE, CREATE_DESIGN_ROUTE, EDIT_BANNERS_ROUTE, EDIT_NEWS_ROUTE, EDIT_PRICES_ROUTE,
    LAYOUTS_AND_DESIGN_ROUTE, LOGIN_ROUTE,
    MAIN_ROUTE, NEWS_ROUTE,
} from "./utils/const";
import CalculateItem from "./pages/CalculateItem/CalculateItem";
import EditConcretePrices from "./pages/AdminPages/EditPrices/EditConcretePrices/EditConcretePrices";

export const publicRoutes = [
    {
        path: MAIN_ROUTE,
        Component: <MainPage/>
    },
    {
        path: NEWS_ROUTE,
        Component: <News/>
    },
    {
        path: LAYOUTS_AND_DESIGN_ROUTE,
        Component: <LayoutsAndDesign/>
    },
    {
        path: CONTACTS_ROUTE,
        Component: <Contacts/>
    },
    {
        path: CALCULATORS_LIST_ROUTE,
        Component: <CalculatorsList/>
    },
    {
        path: CALCULATE_ROUTE + '/:type',
        Component: <CalculateItem/>
    },
    {
        path: CREATE_DESIGN_ROUTE,
        Component: <CreateDesignMainPage/>
    },
    {
        path: ADMIN_ROUTE,
        Component: <Login/>
    },
    {
        path: LOGIN_ROUTE,
        Component: <Login/>
    },
    {
        path: CREATE_DESIGN_ROUTE + '/business_card',
        Component: <CreateDesign header={'Создать дизайн визитки'}/>
    },
    {
        path: CREATE_DESIGN_ROUTE + '/pin',
        Component: <CreateDesign header={'Создать дизайн значка'}/>
    },
    {
        path: CREATE_DESIGN_ROUTE + '/table',
        Component: <CreateDesign header={'Создать дизайн кабинетной таблички'}/>
    },
    {
        path: CREATE_DESIGN_ROUTE + '/leaflet',
        Component: <CreateDesign header={'Создать дизайн листовки'}/>
    },
]

export const authRoutes = [
    {
        path: EDIT_BANNERS_ROUTE,
        Component: <EditBanners/>
    },
    {
        path: EDIT_NEWS_ROUTE,
        Component: <EditNews/>
    },
    {
        path: EDIT_PRICES_ROUTE,
        Component: <EditPrices/>
    },
    {
        path: EDIT_PRICES_ROUTE + '/:type',
        Component: <EditConcretePrices/>
    }
]