import React, {useContext} from 'react';
import styles from './CalculatorsList.module.css'
import {Col, Container, Row} from "react-bootstrap";
import CalculatorsListElement from "../../components/CalculatorsListElement/CalculatorsListElement";
import businessCard from "../../images/businessCard.png"
import leaflet from "../../images/leaflet.png"
import table from '../../images/table.png'
import shield from '../../images/shield.png'
import stand from '../../images/stand.png'
import drawing from '../../images/drawing.png'
import poster from '../../images/poster.png'
import postcard from '../../images/postcard.png'
import discountCard from '../../images/discountCard.png'
import banner from '../../images/banner.png'
import {Context} from "../../index";

const CalculatorsList = () => {
    const {global} = useContext(Context);
    global.setIsAdminPage(false);

    return (
        <Container>
            <div className={styles.calculatorListHeader}>
                Выберите расчёт стоимости нужного изделия
            </div>
            <Row>
                <Col lg={4} md={6} className='mt-4'>
                    <CalculatorsListElement image={businessCard} name={'Визитки'} text={'Односторонние, двусторонние'} route={'/businessCard'}/>
                </Col>
                <Col lg={4} md={6} className='mt-4'>
                    <CalculatorsListElement image={leaflet} name={'Листовки'} text={'А3, А4, А5, А6'} route={'/leaflet'}/>
                </Col>
                <Col lg={4} md={6} className='mt-4'>
                    <CalculatorsListElement image={discountCard} name={'Дисконтные карты'} text={'Матовые, глянцевые'} route={'/discountCard'}/>
                </Col>
                <Col lg={4} md={6} className='mt-4'>
                    <CalculatorsListElement image={postcard} name={'Открытки "Евро"'} text={'Разных форм'} route={'/postcard'}/>
                </Col>
                <Col lg={4} md={6} className='mt-4'>
                    <CalculatorsListElement image={table} name={'Таблички'} text={''} route={'/table'}/>
                </Col>
                <Col lg={4} md={6} className='mt-4'>
                    <CalculatorsListElement image={shield} name={'Шильды'} text={''} route={'/shield'}/>
                </Col>
                <Col lg={4} md={6} className='mt-4'>
                    <CalculatorsListElement image={poster} name={'Плакаты'} text={'Постеры, картины'} route={'/poster'}/>
                </Col>
                <Col lg={4} md={6} className='mt-4'>
                    <CalculatorsListElement image={drawing} name={'Чертежи'} text={'Чернобелые и цветные'} route={'/drawing'}/>
                </Col>
                <Col lg={4} md={6} className='mt-4'>
                    <CalculatorsListElement image={stand} name={'Стенды'} text={''} route={'/stand'}/>
                </Col>
                <Col className='mt-4'>
                    <CalculatorsListElement image={banner} name={'Баннеры'} text={''} route={'/banner'}/>
                </Col>
            </Row>
        </Container>
    );
};

export default CalculatorsList;