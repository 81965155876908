import {$authHost, $host} from "./index";

export const getPrice = async (type) => {
    const {data} = await $host.get(`price/${type}`);
    return data;
}

export const changePrice = async (itemType, type, price) => {
    const {data} = await $authHost.put(`price/${itemType}`, {type, price});
    return data;
}