import React from 'react';
import styles from './WhyMeElement.module.css'

const WhyMeElement = ({image, text}) => {
    return (
        <div className={styles.whyElement}>
            <div className={styles.whyImageContainer}>
                <img src={image} alt=''/>
            </div>
            <span>{text}</span>
        </div>
    );
};

export default WhyMeElement;