import React, {useContext} from 'react';
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";
import styles from "../Calculation.module.css";
import {Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import widePostcard from "../../../images/wideSide.png";

const PostcardBlockWide = observer(() => {
    const {postcardCalculation} = useContext(Context);
    const tooltip =
        <Tooltip id="tooltip" className='ms-2'>
            Кол-во визиток должно быть кратно 3
        </Tooltip>

    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            <div>
                <img src={widePostcard} className={styles.headerImage} alt=''/>
            </div>
            <div className={styles.group}>
                <div className={styles.label}>
                    Бумага:
                </div>
                <div>
                    <Form.Select aria-label="Default select example" className={styles.selectInput}
                                 onChange={(e) => postcardCalculation.setCalculatingPaperTypeWide(e.target.value)}
                                 value={postcardCalculation.calculatingPaperTypeWide}>
                        <option value={'widePaper300g'} key={'widePaper300g'}>
                            Бумага 300 грамм
                        </option>
                        <option value={'wideDesignPaper1'} key={'wideDesignPaper1'}>
                            Бумага дизайнерская 1-го типа
                        </option>
                        <option value={'wideDesignPaper2'} key={'wideDesignPaper2'}>
                            Бумага дизайнерская 2-го типа
                        </option>
                        <option value={'wideDesignPaper3'} key={'wideDesignPaper3'}>
                            Бумага дизайнерская 3-го типа
                        </option>
                    </Form.Select>
                </div>
            </div>
            <div className={styles.group}>
                <div className={styles.label}>
                    Количество:
                </div>
                <div>
                    <OverlayTrigger placement="right" overlay={tooltip}>
                        <Form.Control type="number" name="count" min={0} step={3}
                                      value={postcardCalculation.calculatingCountWide}
                                      className={styles.selectInput}
                                      onChange={(e) => postcardCalculation.setRealCalculatingCountWide(e.target.value)}
                                      onBlur={(e) => postcardCalculation.setCalculatingCountWide(e.target.value)}
                        />
                    </OverlayTrigger>
                </div>
            </div>
            <div className={styles.totalPriceBlock}>
                <div>
                    Итог: <span
                    className={styles.totalPrice}>{postcardCalculation.totalPriceWide}</span> руб.
                </div>
            </div>
            <div className={styles.info}>
                <span className={styles.redStar}>*</span> Фигурная вырубка, фольгирование, тиснение, оформление
                дополнительными украшениями расчитываются индивидуально в зависимости от макета
            </div>
            <div className={styles.contactUsBlock}>
                <div className={styles.contactUs}>
                    Заказать
                </div>
            </div>
        </Form>
    );
});

export default PostcardBlockWide;