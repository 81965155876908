import React, {useContext} from 'react';
import styles from './Slider.module.css'
import {Carousel} from "react-bootstrap";
import {Context} from "../../index";

const Slider = () => {
    const {banners} = useContext(Context);
    return (
        <Carousel className={styles.slider}>
            {
                banners.banners.map((banner) => (
                    <Carousel.Item>
                        <div className={styles.sliderBlock}>
                            <img src={banner.image} alt={banner.errorMessage} className={styles.sliderBlur}/>
                            <img src={banner.image} alt={banner.errorMessage} className={styles.sliderImage}/>
                        </div>
                    </Carousel.Item>
                ))
            }
        </Carousel>
    );
};

export default Slider;