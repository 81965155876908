import React, {useContext, useEffect, useState} from 'react';
import {Container, Spinner} from "react-bootstrap";
import styles from './EditPricesComponents.module.css'
import EditPricesField from "./EditPricesField";
import {Context} from "../../../../../index";
import {observer} from "mobx-react-lite";

const EditPricesBusinessCard = observer(() => {
    const {businessCardCalculation} = useContext(Context);
    const [isPricesLoaded, setIsPricesLoaded] = useState(false);

    useEffect(() => {
        businessCardCalculation.loadPrices().then(() => setIsPricesLoaded(true));
    }, []);
    return (
        <>
            {isPricesLoaded ?
                <Container>
                    <div className={styles.priceEditorHeader}>
                        Изменение цен для визиток
                    </div>
                    <div className={styles.pricesBlock}>
                        <EditPricesField text='Бумага 300 грамм' type='paper300g'
                                         price={businessCardCalculation.prices['paper300g']} itemType='businessCard'/>
                        <EditPricesField text='Дизайнерская бумага 1-го типа' type='designPaper1'
                                         price={businessCardCalculation.prices['designPaper1']} itemType='businessCard'/>
                        <EditPricesField text='Дизайнерская бумага 2-го типа' type='designPaper2'
                                         price={businessCardCalculation.prices['designPaper2']} itemType='businessCard'/>
                        <EditPricesField text='Дизайнерская бумага 3-го типа' type='designPaper3'
                                         price={businessCardCalculation.prices['designPaper3']} itemType='businessCard'/>
                        <EditPricesField text='Печать 1 сторона' type='printingOneSide'
                                         price={businessCardCalculation.prices['printingOneSide']} itemType='businessCard'/>
                        <EditPricesField text='Печать 2 стороны' type='printingTwoSides'
                                         price={businessCardCalculation.prices['printingTwoSides']} itemType='businessCard'/>
                    </div>
                </Container>
                :
                <Spinner className="spinner" animation={"grow"}/>}
        </>
    );
});

export default EditPricesBusinessCard;