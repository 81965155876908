import React, {useContext} from 'react';
import styles from './ProductSlider.module.css'
import {Carousel} from "react-bootstrap";
import addressPlate from '../../images/addressPlate.png'
import banner from '../../images/banner.png'
import badge from '../../images/badge.png'
import form from '../../images/form.png'
import notebook from '../../images/notebook.png'
import booklet from '../../images/booklet.png'
import businessCard from '../../images/businessCard.png';
import wobbler from '../../images/wobbler.png'
import signboard from '../../images/signboard.png'
import pennant from '../../images/pennant.png'
import disk from '../../images/disk.png'
import documentation from '../../images/documentation.png'
import magazine from '../../images/magazine.png'
import pin from '../../images/pin.png'
import calendar from '../../images/calendar.png'
import card from '../../images/discountCard.png'
import catalog from '../../images/catalog.png'
import envelope from '../../images/envelope.png'
import mug from '../../images/mug.png'
import kubarik from '../../images/kubarik.png'
import leaflet from '../../images/leaflet.png'
import magnet from '../../images/magnet.png'
import menu from '../../images/menu.png'
import mobileStand from '../../images/mobileStand.png'
import sticker from '../../images/sticker.png'
import checkNumber from '../../images/checkNumbers.png'
import wallpaper from '../../images/wallpaper.png'
import volumetricLetter from '../../images/volumetricLetter.png'
import postCard from '../../images/postcard.png'
import puzzle from '../../images/puzzle.png'
import packages from '../../images/packages.png'
import folder from '../../images/folder.png'
import stamp from '../../images/stamp.png'
import printing from '../../images/printing.png'
import poster from '../../images/poster.png'
import priceList from '../../images/priceList.png'
import invitation from '../../images/invitation.png'
import pen from '../../images/pen.png'
import certificate from '../../images/certificate.png'
import stele from '../../images/stele.png'
import stand from '../../images/stand.png'
import souvenir from '../../images/souvenir.png'
import table from '../../images/table.png'
import certification from '../../images/certification.png'
import flyer from '../../images/flyer.png'
import flash from '../../images/flashDriver.png'
import tShirt from '../../images/tShirt.png'
import priceTag from '../../images/priceTag.png'
import drawing from '../../images/drawing.png'
import shield from '../../images/shield.png'
import pillar from '../../images/pillar.png'
import label from '../../images/label.png'
import bookleting from '../../images/bookleting.png'
import design from '../../images/design.png'
import copyEngineer from '../../images/copyEngineer.png'
import copying from '../../images/copying.png'
import laser from '../../images/laser.png'
import lamination from '../../images/lamination.png'
import installation from '../../images/installation.png'
import metalPrinting from '../../images/metalPrinting.png'
import fabricPrinting from '../../images/fabricPrinting.png'
import fullConstruction from '../../images/fullConstruction.png'
import scanning from '../../images/scanning.png'
import padPrinting from '../../images/padPrinting.png'
import embossing from '../../images/embossing.png'


const ProductSlider = () => {
    return (
        <Carousel className={styles.slider} id='productSlider' indicators={false}>
            <Carousel.Item>
                <div className={styles.flex}>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={disk} alt=''/>
                        </div>
                        <span>Диски</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={banner} alt=''/>
                        </div>
                        <span>Баннеры</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={badge} alt=''/>
                        </div>
                        <span>Бэйджи</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={form} alt=''/>
                        </div>
                        <span>Бланки</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={notebook} alt=''/>
                        </div>
                        <span>Блокноты</span>
                    </div>
                </div>
                <div className={styles.flex}>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={booklet} alt=''/>
                        </div>
                        <span>Буклеты</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={businessCard} alt=''/>
                        </div>
                        <span>Визитки</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={wobbler} alt=''/>
                        </div>
                        <span>Воблеры</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={signboard} alt=''/>
                        </div>
                        <span>Вывески</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={pennant} alt=''/>
                        </div>
                        <span>Вымпелы</span>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className={styles.flex}>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={addressPlate} alt=''/>
                        </div>
                        <span>Адресные таблички</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={documentation} alt=''/>
                        </div>
                        <span>Документация</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={magazine} alt=''/>
                        </div>
                        <span>Журналы</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={pin} alt=''/>
                        </div>
                        <span>Значки</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={calendar} alt=''/>
                        </div>
                        <span>Календари</span>
                    </div>
                </div>
                <div className={styles.flex}>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={card} alt=''/>
                        </div>
                        <span>Карты</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={catalog} alt=''/>
                        </div>
                        <span>Каталоги</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={envelope} alt=''/>
                        </div>
                        <span>Конверты</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={mug} alt=''/>
                        </div>
                        <span>Кружки</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={kubarik} alt=''/>
                        </div>
                        <span>Кубарики</span>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className={styles.flex}>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={leaflet} alt=''/>
                        </div>
                        <span>Листовки</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={magnet} alt=''/>
                        </div>
                        <span>Магниты</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={menu} alt=''/>
                        </div>
                        <span>Меню</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={mobileStand} alt=''/>
                        </div>
                        <span>Мобильные стенды</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={sticker} alt=''/>
                        </div>
                        <span>Наклейки</span>
                    </div>
                </div>
                <div className={styles.flex}>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={checkNumber} alt=''/>
                        </div>
                        <span>Номерки</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={wallpaper} alt=''/>
                        </div>
                        <span>Обои</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={volumetricLetter} alt=''/>
                        </div>
                        <span>Объемные буквы</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={postCard} alt=''/>
                        </div>
                        <span>Открытки</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={puzzle} alt=''/>
                        </div>
                        <span>Пазлы</span>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className={styles.flex}>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={packages} alt=''/>
                        </div>
                        <span>Пакеты</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={folder} alt=''/>
                        </div>
                        <span>Папки</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={stamp} alt=''/>
                        </div>
                        <span>Печати и штампы</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={printing} alt=''/>
                        </div>
                        <span>Печать широкоформатная</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={poster} alt=''/>
                        </div>
                        <span>Плакаты</span>
                    </div>
                </div>
                <div className={styles.flex}>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={priceList} alt=''/>
                        </div>
                        <span>Прайс-листы</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={invitation} alt=''/>
                        </div>
                        <span>Пригласительные</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={pen} alt=''/>
                        </div>
                        <span>Ручки</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={certificate} alt=''/>
                        </div>
                        <span>Сертификаты</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={stele} alt=''/>
                        </div>
                        <span>Стеллы</span>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className={styles.flex}>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={stand} alt=''/>
                        </div>
                        <span>Стенды</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={souvenir} alt=''/>
                        </div>
                        <span>Сувениры</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={table} alt=''/>
                        </div>
                        <span>Таблички</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={certification} alt=''/>
                        </div>
                        <span>Удостоверения</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={flyer} alt=''/>
                        </div>
                        <span>Флаеры</span>
                    </div>
                </div>
                <div className={styles.flex}>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={flash} alt=''/>
                        </div>
                        <span>Флэшки</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={tShirt} alt=''/>
                        </div>
                        <span>Футболки</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={priceTag} alt=''/>
                        </div>
                        <span>Ценники</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={drawing} alt=''/>
                        </div>
                        <span>Чертежи</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={shield} alt=''/>
                        </div>
                        <span>Шильды</span>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className={styles.flex}>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={pillar} alt=''/>
                        </div>
                        <span>Штендеры</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={label} alt=''/>
                        </div>
                        <span>Этикетки</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={bookleting} alt=''/>
                        </div>
                        <span>Брошюрирование</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={design} alt=''/>
                        </div>
                        <span>Дизайн</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={copyEngineer} alt=''/>
                        </div>
                        <span>Копирование
                            инженерки</span>
                    </div>
                </div>
                <div className={styles.flex}>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={copying} alt=''/>
                        </div>
                        <span>Копирование</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={laser} alt=''/>
                        </div>
                        <span>Лазер</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={lamination} alt=''/>
                        </div>
                        <span>Ламинирование</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={installation} alt=''/>
                        </div>
                        <span>Монтаж</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={metalPrinting} alt=''/>
                        </div>
                        <span>Печать на металле</span>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className={styles.flex}>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={fabricPrinting} alt=''/>
                        </div>
                        <span>Печать на ткани</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={fullConstruction} alt=''/>
                        </div>
                        <span>Под ключ</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={scanning} alt=''/>
                        </div>
                        <span>Сканирование</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={padPrinting} alt=''/>
                        </div>
                        <span>Тампопечать</span>
                    </div>
                    <div className={styles.SliderElement}>
                        <div className={styles.SliderContainer}>
                            <img src={embossing} alt=''/>
                        </div>
                        <span>Тиснение</span>
                    </div>
                </div>
            </Carousel.Item>
        </Carousel>
    );
};

export default ProductSlider;