import React from 'react';
import styles from './postBody.module.css'
import convertDate from "../../utils/convertDate";
import PostImagesContainer from "../PostImagesContainer/PostImagesContainer";
import {observer} from "mobx-react-lite";

const PostBody = observer(({data, index}) => {
    return (
        <div>
            <h2 className={styles.title}>{data.header}</h2>
            <span className={styles.date}>{convertDate(data.created_at)}</span><br />
            <div className={styles.imageContainer}>
                <PostImagesContainer index={index} />
            </div>

            <div className={styles.newsText}>
                {data.text}
            </div>
        </div>
    );
});

export default PostBody;