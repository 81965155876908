import styles from './PricingSub.module.css'

const PricingSub = ({ title, price }) => {

    return (
        <div className={styles.content}>
            <div className={styles.contentText}>{title}</div>
            <div className={styles.contentPrice}>{price}</div>
        </div>
    );
};

export default PricingSub;