import React, {useContext, useState} from 'react';
import styles from './NavigationPanel.module.css'
import {Link, useNavigate} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {Context} from "../../index";
import {
    CALCULATORS_LIST_ROUTE, CONTACTS_ROUTE, CREATE_DESIGN_ROUTE,
    EDIT_BANNERS_ROUTE, EDIT_NEWS_ROUTE, EDIT_PRICES_ROUTE,
    EXAMPLES_ROUTE,
    LAYOUTS_AND_DESIGN_ROUTE,
    LOGIN_ROUTE,
    MAIN_ROUTE,
    NEWS_ROUTE, PAYMENT_AND_DELIVERY_ROUTE
} from "../../utils/const";
import logo from '../../images/logo.png'
import {observer} from "mobx-react-lite";

const NavigationPanel = observer(() => {
    const {user} = useContext(Context);
    const {global} = useContext(Context);
    const navigate = useNavigate();


    return (
        <Navbar className={styles.navbar} id="navbar" expand='lg'>
            <Navbar.Brand as={Link} to={MAIN_ROUTE}>
                <img
                    src={logo}
                    className={styles.logo}
                    alt="Logo"
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                {global.isAdminPage ?
                    <>
                        {user.isAuth ?
                            <>
                                <Nav className="me-auto ms-5">
                                    <Nav.Link as={Link} to={EDIT_BANNERS_ROUTE}
                                              className={styles.navItem}>Баннеры</Nav.Link>
                                    <Nav.Link as={Link} to={EDIT_NEWS_ROUTE}
                                              className={styles.navItem}>Новости</Nav.Link>
                                    {/*<Nav.Link as={Link} to={EDIT_PRICES_ROUTE}*/}
                                    {/*          className={styles.navItem}>Цены</Nav.Link>*/}
                                </Nav>
                                <Nav className="ms-auto">
                                    <Nav.Link onClick={() => {
                                        user.authOut();
                                        navigate(LOGIN_ROUTE);
                                    }} className={styles.navItem}>Выйти</Nav.Link>
                                </Nav>
                            </>
                            :
                            <Nav className="ms-auto">
                                <Nav.Link onClick={() => {
                                    navigate(LOGIN_ROUTE);
                                }} className={styles.navItem}>Войти</Nav.Link>
                            </Nav>
                        }
                    </>
                    :
                    <>
                        <Nav className="me-auto ms-5">
                            <Nav.Link as={Link} to={NEWS_ROUTE} className={styles.navItem}>Новости</Nav.Link>
                            <Nav.Link as={Link} to={LAYOUTS_AND_DESIGN_ROUTE} className={styles.navItem}>Макеты и&nbsp;дизайн</Nav.Link>
                            {/*<Nav.Link as={Link} to={CALCULATORS_LIST_ROUTE} className={styles.navItem}>Расчёт стоимости</Nav.Link>*/}
                            <Nav.Link as={Link} to={CONTACTS_ROUTE} className={styles.navItem}>Контакты</Nav.Link>
                        </Nav>
                        {/*<Nav>*/}
                        {/*    <Nav.Link as={Link} to={CREATE_DESIGN_ROUTE} className={`${styles.navItem} ${styles.createOwnDesign}`}>Создать свой дизайн</Nav.Link>*/}
                        {/*</Nav>*/}
                    </>
                }
            </Navbar.Collapse>
        </Navbar>
    );
});

export default NavigationPanel;