import axios from "axios";
import {BASE_URL} from "../utils/const";

export const sendForm = async (name, email, text, file = null) => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('text', text);

    if (file) {
        formData.append('file', file);
    }

    await axios.post(BASE_URL + 'mail/send_form', formData, {
        headers: {
            "Content-type": "multipart/form-data",
        },
    });
}