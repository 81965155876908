import React, {useContext} from 'react';
import styles from './ContactUsForm.module.css'
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ContactUsMainForm from "../ContactUsMainForm/ContactUsMainForm";
import ContactUs from "../ContactUs/ContactUs";
import {FormGroup} from "react-bootstrap";
import swal from "sweetalert";
import {observer} from "mobx-react-lite";
import {Context} from "../../index";

const ContactUsForm = observer(() => {
    const {contacts} = useContext(Context);

    const handler = () => {
        contacts.sendForm().then(() => {
            swal("Успешно!", "Письмо отправлено", "success", {
                buttons: false,
            }).then(contacts.clearAllFields());
        });
    }

    return (
        <div className={styles.contactForm}>
            <h3>Напишите нам!</h3>
            <div className={styles.inputClass}>
                <Form.Group className="mb-3">
                    <Form.Control type="text" placeholder="Ваше имя" value={contacts.name}
                                  onChange={(e) => contacts.setName(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Control type="email" placeholder="E-mail" value={contacts.email}
                                  onChange={(e) => contacts.setEmail(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Control as="textarea" rows={3} placeholder="Ваш вопрос" name="message"
                                  value={contacts.text}
                                  onChange={(e) => contacts.setText(e.target.value)}/>
                </Form.Group>
                <div className={styles.contactUsButtonBlock}>
                    <ContactUs text={'Написать'} color='yellow' handler={handler}/>
                </div>
            </div>
        </div>
    );
});

export default ContactUsForm;