import React, {useContext, useEffect, useState} from 'react';
import styles from './MainPage.module.css';
import {Container, Row, Card, Spinner} from "react-bootstrap";
import {Context} from "../../index";
import Slider from "../../components/Slider/Slider";
import LastPost from "../../components/LastPost/LastPost";
import ContactUsMainForm from "../../components/ContactUsMainForm/ContactUsMainForm";
import WhyMeGroup from "../../components/WhyMeGroup/WhyMeGroup";
import ReadMoreButton from "../../components/readMoreButton/readMoreButton";
import {Carousel} from "react-bootstrap";
import ProductSlider from '../../components/ProductsSlider/ProductSlider';

const MainPage = () => {
    const {banners} = useContext(Context);
    const {global} = useContext(Context);
    const {posts} = useContext(Context);

    global.setIsAdminPage(false);

    const [isSliderLoaded, setIsSliderLoaded] = useState(false);
    const [isPostLoaded, setIsPostLoaded] = useState(false);

    useEffect(() => {
        banners.loadBanners().then(() => setIsSliderLoaded(true));
        posts.loadLastPost().then(() => setIsPostLoaded(true));
    }, [])

    return (
        <>
            {(isPostLoaded && isSliderLoaded)
                ?
                <Container>
                    <div className={styles.slider}>
                        <Slider/>
                    </div>
                    <div className={styles.whyMe}>
                        <h2 className={styles.title}>Почему мы?</h2>
                        <WhyMeGroup/>
                    </div>
                    <div className={styles.news}>
                        <h2 className={styles.title}>Новости</h2>
                        <LastPost/>
                    </div>
                    <div className={styles.readMoreButton}>
                        <ReadMoreButton/>
                    </div>
                    <div className={styles.aboutUs}>
                        <h2 className={styles.title}>О компании</h2>
                        <div className={styles.aboutUsText}>
                            <p>Компания <span>"4 Цвета"</span> -&nbsp;это волшебники полиграфии, способные воплотить
                                ваши
                                идеи в&nbsp;качественную и&nbsp;яркую полиграфическую продукцию.</p>
                            <p>С&nbsp;гордостью заявляем, что наша команда уже целое десятилетие украшает рынок
                                полиграфических услуг, завоевывая сердца сотен клиентов. Мы создали и&nbsp;воплотили
                                в&nbsp;жизнь бесчисленное множество изделий - от&nbsp;маленьких значков до&nbsp;огромных
                                баннеров.</p>
                            <p>Наша компания гордится современным парком многофункционального оборудования,
                                высококвалифицированным персоналом и&nbsp;огромным запасом опыта.</p>
                            <p>Наш успех исчисляется не&nbsp;только количеством постоянных заказчиков, но и&nbsp;их
                                восторженными
                                отзывами. Они выбирают нас снова и&nbsp;снова, потому что работать с&nbsp;нами - это
                                комфорт и&nbsp;уверенность в результате. Мы процветаем, обретая доверие людей, укрепляя
                                репутацию
                                и&nbsp;подтверждая высокий профессионализм, оперативность и&nbsp;мобильность
                                в&nbsp;каждой задаче. Ведь мы просто обожаем то, чем занимаемся!</p>
                            <p> Вы можете связаться с&nbsp;нами любым способом: через форму обратной связи на&nbsp;нашем
                                сайте,
                                по&nbsp;телефону или&nbsp;почте, указанным в&nbsp;разделе контактов. Мы с&nbsp;радостью
                                поможем вам воплотить
                                каждую идею в&nbsp;жизнь, сделав ее неповторимой и&nbsp;яркой!</p>
                        </div>
                    </div>
                    <div className={styles.productSlider}>
                        <h2 className={styles.title}>Что мы умеем:</h2>
                        <ProductSlider/>
                    </div>
                    <div className={styles.contactsMain}>
                        <h2 className={`${styles.title} ${styles.anyQuestionsTitle}`}>Остались вопросы?</h2>
                        <ContactUsMainForm/>
                    </div>
                </Container>
                :
                <Spinner className="spinner" animation={"grow"}/>}
        </>
    );
};

export default MainPage;