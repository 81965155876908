import React, {useState} from 'react';
import styles from './DesignEditorBusinessCard.module.css'
import {Col, Row} from "react-bootstrap";
import BusinessCard from "./BusinessCard/BusinessCard";
import BusinessCardParameters from "./BusinessCard/BusinessCardParameters";
import ContactUsWithFile from "../../modals/contactUsWithFile/contactUsWithFile";

const DesignEditorBusinessCard = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <Row className={styles.editor}>
                <Col lg={8} className={styles.canvas}>
                    <BusinessCard/>
                </Col>
                <Col lg={4} className={styles.parameters}>
                    <BusinessCardParameters/>
                    <div className={styles.contactUsBlock}>
                        <div className={styles.contactUs} onClick={() => setIsModalOpen(true)}>
                            Отправить нам
                        </div>
                    </div>
                </Col>
            </Row>
            <ContactUsWithFile show={isModalOpen} onHide={() => setIsModalOpen(false)} type={'businessCard'}/>
        </>
    );
};

export default DesignEditorBusinessCard;