import React from 'react';
import styles from './DesignEditorTable.module.css'

const DesignEditorTable = () => {
    return (
        <div>
            табличка
        </div>
    );
};

export default DesignEditorTable;