import React, {useContext} from 'react';
import styles from '../Calculation.module.css'
import {Context} from "../../../index";
import {Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import fullPostcard from '../../../images/fullSize.png'

const PostcardBlockFull = observer(() => {
    const {postcardCalculation} = useContext(Context);
    const tooltip =
        <Tooltip id="tooltip" className='ms-2'>
            Кол-во визиток должно быть кратно 3
        </Tooltip>

    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            <div>
                <img src={fullPostcard} className={styles.headerImage} alt=''/>
            </div>
            <div className={styles.group}>
                <div className={styles.label}>
                    Тип печати:
                </div>
                <div>
                    <Form.Select aria-label="Default select example" className={styles.selectInput}
                                 onChange={(e) => postcardCalculation.setCalculatingPrintingType(e.target.value)}
                                 value={postcardCalculation.calculatingPrintingType}>
                        <option value={'full4Plus1Paper'} key={'full4Plus1Paper'}>
                            4+1
                        </option>
                        <option value={'full4Plus4Paper'} key={'full4Plus4Paper'}>
                            4+4
                        </option>
                    </Form.Select>
                </div>
            </div>
            <div className={styles.group}>
                <div className={styles.label}>
                    Количество:
                </div>
                <div>
                    <OverlayTrigger placement="right" overlay={tooltip}>
                        <Form.Control type="number" name="count" min={0} step={3}
                                      value={postcardCalculation.calculatingCountFull}
                                      className={styles.selectInput}
                                      onChange={(e) => postcardCalculation.setRealCalculatingCountFull(e.target.value)}
                                      onBlur={(e) => postcardCalculation.setCalculatingCountFull(e.target.value)}
                        />
                    </OverlayTrigger>
                </div>
            </div>
            <div className={styles.totalPriceBlock}>
                <div>
                    Итог: <span
                    className={styles.totalPrice}>{postcardCalculation.totalPriceFull}</span> руб.
                </div>
            </div>
            <div className={styles.info}>
                <span className={styles.redStar}>*</span> Фигурная вырубка, фольгирование, тиснение, оформление
                дополнительными украшениями расчитываются индивидуально в зависимости от макета
            </div>
            <div className={styles.contactUsBlock}>
                <div className={styles.contactUs}>
                    Заказать
                </div>
            </div>
        </Form>
    );
});

export default PostcardBlockFull;