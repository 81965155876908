import React, {useContext, useEffect, useState} from 'react';
import styles from './EditBanners.module.css';
import {Button, Card, Container, OverlayTrigger, Spinner, Tooltip,} from "react-bootstrap";
import {Context} from '../../../index';
import {observer} from 'mobx-react-lite';
import swal from 'sweetalert';
import ModalCreateAndUpdateBanner from "../../../components/modals/modalBannerEditor/modalCreateAndUpdateBanner";

const EditBanners = observer(() => {
    const {global} = useContext(Context);
    const {banners} = useContext(Context);
    global.setIsAdminPage(true);

    const [isBannersLoaded, setIsBannersLoaded] = useState(false);
    const [show, setShow] = useState(false);
    const [modalType, setModalType] = useState('create');

    useEffect(() => {
        setIsBannersLoaded(false);
        banners.loadBanners().then(() => setIsBannersLoaded(true));
    }, [])

    const showAlert = () => {
        swal({
            title: "Вы уверены, что хотите удалить баннер?",
            icon: "warning",
            buttons: {
                cancel: "Нет, не удалять",
                catch: {
                    text: "Да, удалить!",
                    value: "Да, удалить!",
                },
            },
        })
            .then((value) => {
                switch (value) {

                    case "Да, удалить!":
                        swal("Удаление баннера", "Баннер успешно удален!", "success");
                        banners.deleteBanner();
                        break;
                }
            });
    }

    const tooltip =
        <Tooltip id="tooltip" className='mb-2 mt-2'>
            Идеальный размер баннера - 900px * 300px!
        </Tooltip>

    return (
        <>
            {
                isBannersLoaded ?
                    <>
                        <Container>
                            <OverlayTrigger placement="top" overlay={tooltip}>
                                <Button className={styles.createBanner} type='submit' onClick={
                                    () => {
                                        setShow(true);
                                        setModalType('create')
                                    }
                                }>
                                    Загрузить баннер
                                </Button>
                            </OverlayTrigger>

                            {isBannersLoaded ? banners.banners.map((el) => (
                                    <Card className={styles.card}>
                                        <div className={styles.containerBanner}>
                                            <img src={el.image} className={styles.banner} alt={banners.errorMessage}/>
                                        </div>
                                        <div className={styles.buttons}>
                                            <Button className={styles.deleteBanner} type='submit' onClick={
                                                () => {
                                                    banners.setEditingBannerId(el.id);
                                                    showAlert();
                                                }
                                            }>
                                                Удалить баннер
                                            </Button>
                                            <OverlayTrigger placement="bottom" overlay={tooltip}>
                                                <Button className={styles.editBanner} type='submit' onClick={
                                                    () => {
                                                        setShow(true);
                                                        setModalType('update');
                                                        banners.setEditingBannerId(el.id);
                                                    }
                                                }>
                                                    Редактировать баннер
                                                </Button>
                                            </OverlayTrigger>
                                        </div>
                                    </Card>
                                ))
                                :
                                ''
                            }
                            {
                                banners.error && (() => {
                                    swal("Ошибка!", banners.errorMessage);
                                })
                            }
                            <ModalCreateAndUpdateBanner show={show} type={modalType} onHide={() => setShow(false)}/>
                        </Container>
                    </>
                    :
                    <Spinner className="spinner" animation={"grow"}/>
            }
        </>
    );
});

export default EditBanners;