import {$authHost, $host} from "./index";
import axios from "axios";
import {BASE_URL} from "../utils/const";

export const getAllBanners = async () => {
    const {data} = await $host.get('banner');
    return data;
}

export const dropBanner = async (id) => {
    const {data} = await $authHost.delete(`banner/${id}`);
    return data;
}

export const storeBanner = async () => {
    const formData = new FormData();
    const file = document.getElementById('customFile');
    formData.append('image', file.files[0]);
    await axios.post(BASE_URL + 'banner', formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
        },
    })
}

export const editBanner = async (bannerId) => {
    const formData = new FormData();
    const file = document.getElementById('customFile');
    formData.append('image', file.files[0]);
    await axios.post(BASE_URL + 'banner/' + bannerId, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
        },
    })
}