import React, {useContext, useEffect, useState} from 'react';
import styles from './News.module.css';
import {Card, Container, Spinner} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import PostBody from "../../components/postBody/postBody";
import PaginationBlock from '../../components/Pagination/PaginationBlock'
import {Context} from "../../index";
import {NEWS_ROUTE} from "../../utils/const";
import ErrorPage from "../ErrorPage/ErrorPage";

const News = observer(() => {
    const {posts} = useContext(Context);
    const {global} = useContext(Context);
    global.setIsAdminPage(false);
    const [isPostsLoaded, setIsPostsLoaded] = useState(false);

    const [pageNumber, setPageNumber] = useState(window.location.search.split('=')[1] ?? '');
    useEffect(() => {
        setIsPostsLoaded(false);
        posts.loadPosts(pageNumber).then(() => setIsPostsLoaded(true));
    }, [pageNumber])

    if (posts.error) {
        return <ErrorPage/>
    }

    return (
        <>
            {isPostsLoaded ?
                (
                    <>
                        <Container>
                            {posts.posts.map((el, index) => (
                                <Card className={styles.card}>
                                    <PostBody data={el} index={index} key={el.id}/>
                                </Card>
                            ))}
                        </Container>
                        <div className={styles.pagination}>
                            <PaginationBlock count={posts.pagination.count} current={posts.pagination.current}
                                             route={NEWS_ROUTE} setPageNumber={setPageNumber}/>
                        </div>
                    </>
                )
                :
                <Spinner className="spinner" animation={"grow"}/>
            }
        </>
    );
});

export default News;