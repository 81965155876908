import React, {useContext, useEffect, useState} from 'react';
import styles from '../Calculation.module.css'
import {Context} from "../../../index";
import {Container, Form, OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import {observer} from "mobx-react-lite";

const DiscountCardCalculation = observer(() => {
    const {discountCardCalculation} = useContext(Context);
    const [isPricesLoaded, setIsPricesLoaded] = useState(false);

    useEffect(() => {
        discountCardCalculation.loadPrices().then(() => setIsPricesLoaded(true));
    }, []);

    const tooltip =
        <Tooltip id="tooltip" className='ms-2'>
            Кол-во карт должно быть кратно 21
        </Tooltip>

    return (
        <Container>
            {
                isPricesLoaded ?

                    <Form className={styles.calculatorCard} onSubmit={(e) => e.preventDefault()}>
                        <div className={styles.calculateHeader}>
                            Расчёт стоимости дисконтных карт
                        </div>
                        <div className={styles.group}>
                            <div className={styles.label}>
                                Ламинация:
                            </div>
                            <div>
                            <span className={styles.choiceBlock}>
                                <input type="radio" name="pickerSide" value='glossyLamination' id='glossyLamination'
                                       checked={discountCardCalculation.calculatingLaminationType === 'glossyLamination'}
                                       onChange={(e) => discountCardCalculation.setCalculatingLaminationType(e.target.value)}/>
                                <label htmlFor='glossyLamination' className={styles.choice}>Глянцевая</label>
                            </span>
                                <span className={styles.choiceBlock}>
                                    <input type="radio" name="pickerSide" value='matteLamination' id='matteLamination'
                                           checked={discountCardCalculation.calculatingLaminationType === 'matteLamination'}
                                           onChange={(e) => discountCardCalculation.setCalculatingLaminationType(e.target.value)}/>
                                    <label htmlFor='matteLamination' className={styles.choice}>Матовая</label>
                            </span>
                            </div>
                        </div>
                        <div className={styles.group}>
                            <div className={styles.label}>
                                Количество:
                            </div>
                            <div>
                                <OverlayTrigger placement="right" overlay={tooltip}>
                                    <Form.Control type="number" name="count" step={21} min={0}
                                                  value={discountCardCalculation.calculatingCount}
                                                  className={styles.selectInput}
                                                  onChange={(e) => discountCardCalculation.setRealCalculatingCount(e.target.value)}
                                                  onBlur={(e) => discountCardCalculation.setCalculatingCount(e.target.value)}
                                    />
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className={styles.totalPriceBlock}>
                            <div>
                                Итог: <span
                                className={styles.totalPrice}>{discountCardCalculation.totalPrice}</span> руб.
                            </div>
                        </div>
                        <div className={styles.contactUsBlock}>
                            <div className={styles.contactUs}>
                                Заказать
                            </div>
                        </div>
                    </Form>

                    :
                    <Spinner className="spinner" animation={"grow"}/>
            }
        </Container>
    );
});

export default DiscountCardCalculation;