import React, {useContext} from 'react';
import styles from './BusinessCardParameters.module.css'
import exampleSample1 from '../../../../images/businessCardSamples/exampleSample1.png'
import exampleSample2 from '../../../../images/businessCardSamples/exampleSample2.png'
import exampleSample3 from '../../../../images/businessCardSamples/exampleSample3.png'
import exampleSample4 from '../../../../images/businessCardSamples/exampleSample4.png'
import {Context} from "../../../../index";
import {observer} from "mobx-react-lite";
import BusinessCardParameterBlock from "./BusinessCardParameterBlock/BusinessCardParameterBlock";
import {Form} from "react-bootstrap";

const BusinessCardParameters = observer(() => {
    const {businessCard} = useContext(Context);
    return (
        <div className={styles.parametersBody}>
            <div className={styles.parameterName}>
                Выберите шаблон
            </div>
            <div className={styles.chooseSampleBlock}>
                <div className={styles.scrolledBody}>
                    <BusinessCardParameterBlock number={1} image={exampleSample1}/>
                    <BusinessCardParameterBlock number={2} image={exampleSample2}/>
                    <BusinessCardParameterBlock number={3} image={exampleSample3}/>
                    <BusinessCardParameterBlock number={4} image={exampleSample4}/>
                </div>
            </div>
            <div className={styles.parameterName}>
                Введите данные
            </div>
            <Form>
                <Form.Group className="mb-2">
                    <Form.Label>ФИО</Form.Label>
                    <Form.Control onChange={(e) => businessCard.setName(e.target.value)} value={businessCard.name}/>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Должность</Form.Label>
                    <Form.Control onChange={(e) => businessCard.setJobTitle(e.target.value)} value={businessCard.jobTitle}/>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Название компании</Form.Label>
                    <Form.Control onChange={(e) => businessCard.setHeader(e.target.value)} value={businessCard.header}/>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Телефон</Form.Label>
                    <Form.Control onChange={(e) => businessCard.setPhone(e.target.value)} value={businessCard.phone}/>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control onChange={(e) => businessCard.setEmail(e.target.value)} value={businessCard.email}/>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Адрес</Form.Label>
                    <Form.Control onChange={(e) => businessCard.setAddress(e.target.value)} value={businessCard.address}/>
                </Form.Group>
            </Form>
        </div>
    );
});

export default BusinessCardParameters;